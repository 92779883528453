import React, { useEffect, useState, useRef } from "react";
import { useLanguage } from "../../../context/LanguageContext"; // Import LanguageContext
import { useNavigate } from "react-router-dom";
import "./ServicePlansAdmin.css"; // Updated styling
import { API_BASE_URL } from '../../../config';

const ServicePlansAdmin = () => {
  const { selectedLanguage, isLanguageLoaded } = useLanguage(); // Consume language and loading status
  const navigate = useNavigate();
  const [servicePlans, setServicePlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const serviceRefs = useRef([]);

  // Fetch service plans when the language is loaded
  useEffect(() => {
    if (!isLanguageLoaded) return; // Wait for the language to be loaded

    const fetchServicePlans = async () => {
      const token = localStorage.getItem("adminToken"); // Get token from localStorage
      if (!token) {
        navigate("/admin-login"); // Redirect if no token
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/service_plans/?language=${selectedLanguage}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include token in header
          },
        });

        if (response.status === 401) {
          // If 401, token is invalid or missing
          localStorage.removeItem("adminToken");
          navigate("/admin-login");
          return;
        }

        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

        const data = await response.json();
        setServicePlans(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error("❌ Error fetching service plans:", err);
        setError(err.message || "Failed to load service plans.");
      } finally {
        setLoading(false);
      }
    };

    fetchServicePlans();
  }, [selectedLanguage, isLanguageLoaded]); // Re-run when language changes or is loaded

  // Handle delete of service plan
  const handleDelete = async (id) => {
    const token = localStorage.getItem("adminToken");
    if (!token) {
      navigate("/admin-login");
      return;
    }

    if (!window.confirm("Are you sure you want to delete this service plan?")) return;

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/service_plans/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        // If 401, token is invalid or missing
        localStorage.removeItem("adminToken");
        navigate("/admin-login");
        return;
      }

      if (!response.ok) throw new Error("Failed to delete service plan");

      // Remove the deleted service plan from the state
      setServicePlans((prevState) => prevState.filter((plan) => plan.id !== id));
    } catch (error) {
      console.error("❌ Error deleting service plan:", error);
      setError("Failed to delete the service plan.");
    }
  };

  // Navigate to edit page
  const handleEdit = (id) => {
    navigate(`/admin-edit-service-plans/${id}`);
  };

  // Navigate to create page
  const handleCreateServicePlan = () => {
    navigate("/admin-create-service-plan");
  };

  return (
    <div className="admin-service-plans">
      <h2 className="admin-title">Manage Service Plans</h2>

      {/* Button to create a service plan */}
      <button
        onClick={handleCreateServicePlan}
        className="create-service-plan-button"
      >
        ➕ Create New Service Plan
      </button>

      {loading ? (
        <p className="loading-text">Loading service plans...</p>
      ) : error ? (
        <p className="error-text">{error}</p>
      ) : servicePlans.length > 0 ? (
        <div className="admin-service-grid">
          {servicePlans.map((plan, index) => (
            <div
              key={plan.id}
              ref={(el) => (serviceRefs.current[index] = el)}
              className="admin-service-card"
            >
              <h3 className="admin-service-title">{plan.title}</h3>
              <p className="admin-service-price">💰 {plan.price}€</p>
              <p className="admin-service-description">{plan.description}</p>

              {plan.features && plan.features.length > 0 ? (
                <ul className="admin-features-list">
                  {plan.features.map((feature) => (
                    <li key={feature.id} className="admin-feature-item">
                      ✅ {feature.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="admin-no-features">No additional features</p>
              )}

              <div className="admin-buttons">
                <button className="edit-button" onClick={() => handleEdit(plan.id)}>
                  ✏️ Edit
                </button>
                <button className="delete-button" onClick={() => handleDelete(plan.id)}>
                  ❌ Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-data-text">No service plans found for the selected language.</p>
      )}
    </div>
  );
};

export default ServicePlansAdmin;
