import React, { useEffect, useRef } from "react";
import "./AboutCTA.css";
import CTAButton from "../../../components/buttons/CTAButton";
import SocialMediaIcons from "../../../components/SocialMediaIcons/SocialMediaIcons";

const AboutCTA = () => {
  const sectionRef = useRef(null);
  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) observer.observe(sectionRef.current);
    elementsRef.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
      elementsRef.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <section ref={sectionRef} className="about-cta lazy-load">
      <h2 ref={(el) => elementsRef.current.push(el)} className="lazy-load">
        Let’s embark on this transformative journey together and unlock your full potential!
      </h2>
      <div className="cta-buttons lazy-load" ref={(el) => elementsRef.current.push(el)}>
        <CTAButton link="/contactus" text="Contact Us" />
        <p className="lazy-load" ref={(el) => elementsRef.current.push(el)}>Follow Us</p>
        <CTAButton link="/booking" text="Book A Consultation" />
      </div>
      <div ref={(el) => elementsRef.current.push(el)} className="social-icons-container lazy-load">
        <SocialMediaIcons />
      </div>
    </section>
  );
};

export default AboutCTA;
