import React from "react";
import SocialMediaIcons from "../../components/SocialMediaIcons/SocialMediaIcons";
import "./LocationSection.css";

const LocationSection = () => {
  return (
    <div className="location-section-container">
      <div className="location-section">
        <h3>Our Location</h3>
        <p>Visit our office at the address below.</p>
        <div className="google-maps-wrapper">
        <iframe
          title="Location Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.6613072126314!2d-3.703790984274576!3d40.41677597936409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42297a0b7f04ab%3A0x8d5dece5dfb7f5b1!2sCalle%20Cañete%2018%2C%20Madrid%2C%20Spain!5e0!3m2!1sen!2sus!4v1636428937417!5m2!1sen!2sus"
          width="100%"
          height="450"
          style={{ border: "none", borderRadius: "10px" }}
          allowFullScreen
          loading="lazy"
        ></iframe>
        </div>
        <h3>Social Media</h3>
        <SocialMediaIcons />
      </div>
    </div>
  );
};

export default LocationSection;
