import React, { useEffect, useState } from "react";

const InstagramFeed = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  // Replace with your actual values 👇
  const IG_USER_ID = "3944114989164325"; // your Instagram Business Account ID
  const ACCESS_TOKEN = "EAAJGucUL1gYBOZC5dZAq83j0iCw8HCzWwJk0ZAno8ctjF4QqTTT2EoYIdVquwzqqRGbYdNq7DZBUy2hyVT5avBuE0RR9lG4QsrbaucOu4vVCsZB9CYRxcsiHQLSMDv1NZBVJMz8PFP8V3ZBMKmzY8zLG0C9lPTqvXDA7FZA9PU7dZClJMhLjUMLI8bynLar7sicK82f47HI2s60kpIHOojQ1tvaftiXSWHBPgxkQZD"; // your long-lived access token

  const FIELDS = "username,media{caption,media_url,permalink,media_type,timestamp}";

  useEffect(() => {
    const fetchInstagramPosts = async () => {
      try {
        const response = await fetch(
          `https://graph.instagram.com/${IG_USER_ID}?fields=${FIELDS}&access_token=${ACCESS_TOKEN}`
        );
        const data = await response.json();

        if (data.media && data.media.data) {
          setPosts(data.media.data);
        } else {
          setError("No media found or invalid permissions.");
        }
      } catch (err) {
        setError("Failed to fetch Instagram posts.");
        console.error(err);
      }
    };

    fetchInstagramPosts();
  }, []);

  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
      {posts.map((post) => (
        <a
          key={post.id}
          href={post.permalink}
          target="_blank"
          rel="noopener noreferrer"
          className="overflow-hidden rounded-xl shadow hover:shadow-lg transition-all"
        >
          {post.media_type === "IMAGE" || post.media_type === "CAROUSEL_ALBUM" ? (
            <img
              src={post.media_url}
              alt={post.caption || "Instagram post"}
              className="w-full h-auto object-cover"
            />
          ) : post.media_type === "VIDEO" ? (
            <video controls className="w-full">
              <source src={post.media_url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : null}
          {post.caption && (
            <div className="p-2 text-sm">{post.caption.slice(0, 100)}...</div>
          )}
        </a>
      ))}
    </div>
  );
};

export default InstagramFeed;
