import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Default to false, waiting for token verification
  const [loading, setLoading] = useState(true);  // Track loading state
  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setIsAuthenticated(false);
        setLoading(false); // Finished loading
        return;
      }

      try {
        // Use the updated `/admin/current` endpoint for verification
        const response = await fetch(`${API_BASE_URL}/admin/current`, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem("adminToken"); // Remove invalid token
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Verification failed:", error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false); // Finished loading
      }
    };

    verifyToken();
  }, [token]);

  if (loading) {
    return <p>Loading...</p>; // Prevents unauthorized content flash
  }

  return isAuthenticated ? children : <Navigate to="/admin-login" />;
};

export default ProtectedRoute;
