import { useState, useEffect } from "react";
import "./Header.css";
import BurgerMenu from "./BurgerMenu";
import Navbar from "./NavBar";
import LogoSection from "./LogoSection";
import LanguagePicker from "../buttons/LanguagePicker";
import DarkModeToggle from "../buttons/DarkModeToggle";
import CTAButton from "../buttons/CTAButton";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);

  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      lastScrollY = window.scrollY;
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 840);
      if (window.innerWidth > 840) {
        setBurgerOpen(false); // Close mobile menu when resizing to desktop
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleBurger = () => {
    setBurgerOpen((prev) => !prev);
  };

  const closeNavbar = () => {
    setBurgerOpen(false);
  };

  return (
    <header className={`header ${scrolling ? "hidden" : ""}`}>
      {/* First Row: Navigation, Features, CTA */}
      <div className="header-top">

        {/* this is for the feature section */}
        <div className="header-features">
            <DarkModeToggle />
            <LanguagePicker />
          </div>

        {/* this is for navbar items section */}
        {isMobile ? (
          <BurgerMenu isOpen={burgerOpen} toggleBurger={toggleBurger} />
        ) : (
          <Navbar isOpen={burgerOpen} closeNavbar={closeNavbar} />
        )}
        {/* this is the cta button */}
        <CTAButton text="Book a Consultation" link="/book-consultation" />
      </div>

      {/* Second Row: Logo */}
      <div className="header-bottom">
        <LogoSection />
      </div>

      {/* Mobile Navigation (Hidden on Desktop) */}
      {isMobile && <Navbar isOpen={burgerOpen} closeNavbar={closeNavbar} />}
    </header>
  );
};

export default Header;
