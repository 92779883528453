import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SharePost from "../../../components/posts/SharePost";
import DOMPurify from "dompurify";
import "./RenderContent.css";

const RenderContent = ({ content }) => {
  const [sharePostComponents, setSharePostComponents] = useState([]);

  useEffect(() => {
    if (!content) return;

    const sharePostPlaceholders = document.querySelectorAll(".share-component-placeholder");
    const newSharePostComponents = [];

    sharePostPlaceholders.forEach((placeholder) => {
      const postLink = placeholder.dataset.postLink;
      newSharePostComponents.push({ postLink, placeholder });
    });

    setSharePostComponents(newSharePostComponents);
  }, [content]);

  useEffect(() => {
    sharePostComponents.forEach(({ postLink, placeholder }) => {
      ReactDOM.render(<SharePost postLink={postLink} />, placeholder);
    });
  }, [sharePostComponents]);

  if (!content) {
    return <p>No content available.</p>;
  }

  if (typeof content === "string") {
    const sanitizedContent = DOMPurify.sanitize(content);
    const contentWithSharePost = sanitizedContent.replace(
      /<!--SHARE_COMPONENT_PLACEHOLDER -->/g,
      `<div class="share-component-placeholder" data-post-link="${window.location.href}"></div>`
    );

    return (
      <div
        className="blog-container"
        dangerouslySetInnerHTML={{ __html: contentWithSharePost }}
      />
    );
  }

  return <div className="blog-container">{renderStructuredContent(content)}</div>;
};

const renderStructuredContent = (content) => {
  return content.map((item, index) => {
    switch (item.type) {
      case "h2-subtitle":
        return <h2 key={index} className="h2-subtitle">{item.text}</h2>;
      case "p-container":
        return <p key={index} className="p-container">{item.text}</p>;
      case "p-plain-text":
        return <p key={index} className="p-plain-text">{item.text}</p>;
      case "ul":
        return (
          <ul key={index} className="ul">
            {item.children?.map((child, i) => renderStructuredContent([child])[0])}
          </ul>
        );
      case "li-item":
        return <li key={index} className="li-item">{item.text}</li>;
      case "descriptive-ul":
        return (
          <ul key={index} className="descriptive-ul">
            {item.children?.map((child, i) => renderStructuredContent([child])[0])}
          </ul>
        );
      case "descriptive-item":
        return (
          <li key={index} className="descriptive-item">
            <span className="item-name">{item.name}: </span>
            <span className="item-description">{item.description}</span>
          </li>
        );
      case "share-section":
        return <SharePost key={index} postLink={item.postLink} />;
      default:
        return <p key={index} style={{ color: "red" }}>Unsupported content type: {item.type}</p>;
    }
  });
};

export default RenderContent;
