import React from "react";
import { useLanguage } from "../../context/LanguageContext";
import "./LanguagePicker.css";

const LanguagePicker = () => {
  const { selectedLanguage, changeLanguage } = useLanguage(); // ✅ Use selectedLanguage

  return (
    <select
      className="language-picker"
      value={selectedLanguage}
      onChange={(e) => changeLanguage(e.target.value)}
    >
      <option value="en">🇬🇧 English</option>
      <option value="es">🇪🇸 Español</option>
      <option value="gr">🇬🇷 Greek</option>
    </select>
  );
};

export default LanguagePicker;
