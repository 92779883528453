const quickLinksTranslations = {
  en: {
    policies: "Policies",
    pages: "Pages",
    contactInfo: "Contact Info",
    termsOfService: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    cookiesPolicy: "Cookies Policy",
    services: "Services",
    blogs: "Blogs",
    about: "About",
    contact: "Contact",
    email: "Email: thimiosarvanitis@gmail.com",
    phone: "Phone: +34613497305",
    address: "Address: Calle Cañete 18, Madrid, Spain",
  },
  es: {
    policies: "Políticas",
    pages: "Páginas",
    contactInfo: "Información de contacto",
    termsOfService: "Términos de servicio",
    privacyPolicy: "Política de privacidad",
    cookiesPolicy: "Política de cookies",
    services: "Servicios",
    blogs: "Blogs",
    about: "Sobre nosotros",
    contact: "Contacto",
    email: "Correo: thimiosarvanitis@gmail.com",
    phone: "Teléfono: +34613497305",
    address: "Dirección: Calle Cañete 18, Madrid, España",
  },
  gr: {
    policies: "Πολιτικές",
    pages: "Σελίδες",
    contactInfo: "Πληροφορίες Επικοινωνίας",
    termsOfService: "Όροι Χρήσης",
    privacyPolicy: "Πολιτική Απορρήτου",
    cookiesPolicy: "Πολιτική Cookies",
    services: "Υπηρεσίες",
    blogs: "Ιστολόγια",
    about: "Σχετικά",
    contact: "Επικοινωνία",
    email: "Ηλεκτρονικό Ταχυδρομείο: thimiosarvanitis@gmail.com",
    phone: "Τηλέφωνο: +34613497305",
    address: "Διεύθυνση: Calle Cañete 18, Μαδρίτη, Ισπανία",
  },
};

export default quickLinksTranslations;
