import React, { useEffect, useState, useRef } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ConceptsSection.css"; // ✅ Component-specific styling

const ConceptsSection = ({ concepts }) => {
  const { selectedLanguage } = useLanguage();
  const titleRef = useRef(null);
  const sliderRef = useRef(null);
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const translations = {
    en: "Our Approach to Nutrition",
    es: "Nuestro Enfoque en Nutrición",
    gr: "Η Προσέγγισή μας στη Διατροφή"
  };

  const settings = {
    dots: true, // ✅ Show navigation dots
    infinite: true, // ✅ Infinite scrolling
    speed: 500, // ✅ Transition speed
    slidesToShow: 4, // ✅ Start with 4 slides
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3 } }, // ✅ 3 slides on medium screens
      { breakpoint: 900, settings: { slidesToShow: 2 } }, // ✅ 2 slides on smaller screens
      { breakpoint: 600, settings: { slidesToShow: 1 } }, // ✅ 1 slide on mobile
    ],
  };

  useEffect(() => {
    const titleObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsTitleVisible(true);
          titleObserver.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        titleObserver.unobserve(titleRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const sliderObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsSliderVisible(true);
          sliderObserver.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    if (sliderRef.current) {
      sliderObserver.observe(sliderRef.current);
    }

    return () => {
      if (sliderRef.current) {
        sliderObserver.unobserve(sliderRef.current);
      }
    };
  }, []);

  return (
    <section className="concepts-section">
      <h2 ref={titleRef} className={`section-title ${isTitleVisible ? "fade-in-up" : "hidden"}`}>{translations[selectedLanguage]}</h2>

      <div ref={sliderRef} className={`slider-container ${isSliderVisible ? "fade-in-up" : "hidden"}`}>
        <Slider {...settings} className="concepts-carousel">
          {concepts.map((concept) => (
            <div key={concept.id} className="concept-slide">
              <div className="concept-card">
                <img src={concept.imgUrl} alt={concept.title} className="concept-image" />
                <h3 className="concept-title">{concept.title}</h3>
                <p className="concept-description">{concept.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ConceptsSection;
