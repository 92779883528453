import "./BlogDetails.css"
const TestBlog = ()=>{
  return(
  <div className="blog-page"> 
    <section className="blog-page-section">
      <h1 className="title-of-blog-page">Συμπλήρωση Β12 για Βέγκαν και Χορτοφάγους: Βασικές Πληροφορίες και Πηγές</h1>
      <p className="in-out-label">Introducción</p>
      <p>Aprende la importancia de la suplementación de B12 para veganos y vegetarianos, descubre sus fuentes y comprende cómo mantener niveles adecuados de B12 para una salud óptima.</p>
    </section>
    <section className="blog-page-section">
      <h2>Εισαγωγή στη Β12 και οι Ζωτικές της Λειτουργίες στο Σώμα</h2>
      <p>Η βιταμίνη Β12, γνωστή και ως κοβαλαμίνη, είναι μια κρίσιμη υδατοδιαλυτή βιταμίνη, απαραίτητη για διάφορες λειτουργίες του σώματος. Η Β12 διαδραματίζει σημαντικό ρόλο στην υγεία του εγκεφάλου και στη διατήρηση του νευρικού συστήματος (<a href="https://pubmed.ncbi.nlm.nih.gov/38203763/#%3A%7E%3Atext%3DVitB12%20deficiency%20can%20determine%20a%2Cto%20be%20characterized%20in%20depth" className="external-link">1</a>). Είναι ζωτικής σημασίας για τη δημιουργία DNA και ερυθρών αιμοσφαιρίων, συμβάλλοντας στην παραγωγή ενέργειας και στην πρόληψη της αναιμίας (<a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC3257642/" className="external-link">2</a>,<a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC3262614/" className="external-link">3</a>,<a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC3262614/" className="external-link">4</a>).</p>
      <p>Επιπλέον, αυτή η βιταμίνη είναι απαραίτητη για τον μεταβολισμό τόσο των λιπών όσο και των πρωτεϊνών (<a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC7551072/" className="external-link">5</a>,<a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC7400011/" className="external-link">6</a>). Η ανεπάρκειά της μπορεί να οδηγήσει σε σημαντικά προβλήματα υγείας, καθιστώντας τη συμπλήρωση Β12 ιδιαίτερα σημαντική για όσους ακολουθούν μια βέγκαν ή χορτοφαγική διατροφή, καθώς οι φυτικές πηγές είναι περιορισμένες (<a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC11124153/" className="external-link">7</a>,<a href="https://www.researchgate.net/publication/380493860_Exploring_Vitamin_B12_Supplementation_in_the_Vegan_Population_A_Scoping_Review_of_the_Evidence" className="external-link">8</a>,<a href="https://www.researchgate.net/publication/380493860_Exploring_Vitamin_B12_Supplementation_in_the_Vegan_Population_A_Scoping_Review_of_the_Evidence" className="external-link">9</a>)</p>
    </section>
    {/* // <!--share-post--> */}
    <section className="blog-page-section">
      <h2>Οι Προκλήσεις της Διαθεσιμότητας Β12 σε μια Φυτική Διατροφή και η Διατροφική Μαγιά ως Πηγή Β12 για Βέγκαν</h2>
      <p>Η βιταμίνη Β12, ζωτικής σημασίας για τη λειτουργία των νεύρων και τον σχηματισμό αίματος, βρίσκεται κυρίως σε ζωικά προϊόντα, γεγονός που αποτελεί σημαντική πρόκληση για όσους ακολουθούν φυτική διατροφή (<a href="https://pubmed.ncbi.nlm.nih.gov/17959839/#%3A%7E%3Atext%3DSome%20plant%20foods%2C%20dried%20green%2Cof%20vitamin%20B(12)" className="external-link">10</a>). Οι βέγκαν, που διατρέχουν ιδιαίτερο κίνδυνο έλλειψης Β12, πρέπει να αναζητήσουν εναλλακτικές πηγές. Η διατροφική μαγιά, ένα καρύκευμα με γεύση τυριού, συχνά εμπλουτίζεται με Β12 και έτσι αποτελεί μια αξιόπιστη πηγή για όσους αποφεύγουν ζωικά προϊόντα (<a href="https://fdc.nal.usda.gov/fdc-app.html%23/food-details/2486564/nutrients" className="external-link">11</a>,<a href="https://pubmed.ncbi.nlm.nih.gov/11146329/" className="external-link">12</a>). Ωστόσο, οι βέγκαν πρέπει να ελέγχουν τα επίπεδα εμπλουτισμού της διατροφικής μαγιάς και να ενσωματώνουν ποικιλία πηγών Β12 για να εξασφαλίσουν επαρκή πρόσληψη. Οι τακτικές εξετάσεις αίματος μπορούν να βοηθήσουν στη διατήρηση των επιπέδων Β12 και την προσαρμογή της διατροφής αναλόγως.</p>
    </section>
    <section className="blog-page-section">
      <h2>Συμπλήρωση Β12 και ο Ρόλος των Εμπλουτισμένων Τροφίμων</h2>
      <p>Για τους βέγκαν και τους χορτοφάγους, η συμπλήρωση Β12 είναι συχνά απαραίτητη για την αποφυγή ελλείψεων, καθώς οι φυτικές δίαιτες τείνουν να μην περιέχουν επαρκείς πηγές αυτής της ζωτικής βιταμίνης (<a href="https://pubmed.ncbi.nlm.nih.gov/33809274/" className="external-link">13</a>). Τα συμπληρώματα Β12 διατίθενται σε διάφορες μορφές, συμπεριλαμβανομένων δισκίων, καψουλών και ενέσεων (<a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC9822362/" className="external-link">14</a>). Παρέχουν έναν αξιόπιστο τρόπο διατήρησης των επαρκών επιπέδων Β12.</p>
      <p>Τα εμπλουτισμένα τρόφιμα παίζουν επίσης κρίσιμο ρόλο στην πρόσληψη Β12 για όσους ακολουθούν φυτικές δίαιτες. Κοινά εμπλουτισμένα προϊόντα περιλαμβάνουν:</p>
      <ul>
        <li>Εναλλακτικά γάλατα φυτικής προέλευσης (<a href="https://goodkarmafoods.com/" className="external-link">15</a>, <a href="https://www.amazon.com/Silk-Unsweetened-Unflavored-Dairy-Alternative-Individually/dp/B004HK7NT0" className="external-link">16</a>)</li>
        <li>Δημητριακά πρωινού (<a href="https://www.amazon.in/vitamin-b12-fortified-cereals/s?k=vitamin%2Bb12%2Bfortified%2Bcereals" className="external-link">17</a>)</li>
        <li>Διατροφική μαγιά (<a href="https://www.amazon.com/Bragg-Premium-Nutritional-Yeast-Seasoning/dp/B09HJNL75R?th=1" className="external-link">18</a>)</li>
        <li>Υποκατάστατα κρέατος (<a href="https://pubmed.ncbi.nlm.nih.gov/36765980/" className="external-link">19</a>)</li>
      </ul>
      <p>Οι κατασκευαστές προσθέτουν συνθετική Β12 σε αυτά τα τρόφιμα, καθιστώντας τα μια πρακτική προσθήκη σε μια βέγκαν ή χορτοφαγική διατροφή για την εξασφάλιση επαρκούς κατανάλωσης Β12. Η τακτική παρακολούθηση των επιπέδων Β12 μπορεί να βοηθήσει στον καθορισμό της ανάγκης για συμπληρώματα ή προσαρμογές στη διατροφή.</p>
    </section>
    <section className="blog-page-section">
      <h2>Η Σχέση μεταξύ Έλλειψης Β12 και Χρόνιων Ασθενειών</h2>
      <p>Η βιταμίνη Β12 παίζει κρίσιμο ρόλο στη διατήρηση της βέλτιστης υγείας. Η έλλειψή της μπορεί να οδηγήσει σε διάφορες χρόνιες ασθένειες, όπως:</p>
      <ul>
        <li><span className="item-described">Αναιμία:</span> Η Β12 είναι απαραίτητη για τον σχηματισμό ερυθρών αιμοσφαιρίων. Η έλλειψή της μπορεί να προκαλέσει κακοήθη αναιμία, με αποτέλεσμα κόπωση και αδυναμία.</li>
        <li><span className="item-described">Νευροπάθεια:</span> Η Β12 υποστηρίζει τη λειτουργία των νεύρων. Χωρίς αυτήν, μπορεί να προκληθεί μούδιασμα, μυρμήγκιασμα ή ακόμη και νευρική βλάβη.</li>
        <li><span className="item-described">Καρδιαγγειακές παθήσεις:</span> Τα επαρκή επίπεδα Β12 είναι απαραίτητα για τη ρύθμιση της ομοκυστεΐνης, ενός αμινοξέος που, σε υψηλά επίπεδα, αυξάνει τον κίνδυνο καρδιακών παθήσεων.</li>
        <li><span className="item-described">Γνωστική εξασθένηση:</span> Η Β12 είναι σημαντική για την υγεία του εγκεφάλου. Η έλλειψή της μπορεί να οδηγήσει σε απώλεια μνήμης ή άνοια, ιδιαίτερα σε ηλικιωμένους.</li>
        <li><span className="item-described">Υγεία των οστών:</span> Τα χαμηλά επίπεδα Β12 μπορούν να επηρεάσουν την οστική πυκνότητα, οδηγώντας ενδεχομένως σε οστεοπόρωση.</li>
      </ul>
      <p>Για τους βέγκαν και τους χορτοφάγους, η συμπλήρωση είναι συχνά συνιστώμενη για την αποφυγή αυτών των κινδύνων.</p>
    </section>
    <section className="blog-page-section">
      <h2>B12 Supplementation Practical Guide: Frequency, Timing, and Methods</h2>
      <p>When considering B12 supplementation, the frequency, timing, and methods are crucial for maximum absorption (<a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC9695216/" className="external-link">20</a>,<a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC9695216/" className="external-link">21</a>):</p>
      <ul>
        <li><span className="item-described">Frequency:</span>Adults should aim for a daily intake, with recommendations varying from 2.4 to 100 micrograms, depending on individual needs.</li>
        <li><span className="item-described">Timing:</span>Its best to take B12 supplements on an empty stomach to enhance absorption, preferably in the morning.</li>
      </ul>
      <h4 className="underscored">Methods:</h4>
      <ul>
        <li><span className="item-described">Sublingual Tablets:</span>Dissolve under the tongue for direct absorption into the bloodstream.</li>
        <li><span className="item-described">Oral Pills:</span>Swallow with water; pair with a meal containing fat for beter uptake.</li>
        <li><span className="item-described">Sprays:</span>Spray directly into the mouth, targeting the cheek lining for absorption.</li>
        <li><span className="item-described">Injectons:</span>Intramuscular B12 shots are an option for those with absorption problems, typically administered monthly.</li>
      </ul>
    </section>
    <section className="blog-page-section">
      <h2>B12 Supplements</h2>
      <p>Heres a comprehensive guide to the top 10 B12 supplements that can help you achieve optimal health.</p>
      <ul>
        <li><a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC9695216/" className="external-link">Nature Made B12:</a>USP-verified, cyanocobalamin and methylcobalamin, affordable.</li>
        <li><a href="https://www.amazon.com/SOLGAR-Methylcobalamin-Vitamin-5000-B-Complex/dp/B0CSYJSZK2/ref=sr_1_1?crid=2D9AYG45PUOD7&dib=eyJ2IjoiMSJ9.5T9mvMa78wBG8Tx87aDqK-qK58c7GpIbQ1vdOY9NvdX8iktoFkA4IYkEsvsueWiyGB2Cok17-EI6-0JCBT4U0N2_BgOf_nqsKuKB8toGEbuU_xPTIJ12EohiQ6kgVddnj1pOC3UBpgQg9N2Hk6MI9rVUEKy0hqMjiGJV5rh_KxB5kcNJ0vx2vx47VkYWxeiA-35_NzDX3zuNTQgNbzyismkQA3gmwgOpdCdVpJ5iLWMjhV9UfcGk-DsQDPN1mxI14oXkA46lUOQyIpIiSpH_jj57JmUFRgq_7dmPsvrPm4E.c_otVuotS7PE_n7zO-atAk36_7D9n3o4FMcs7mxLlKg&dib_tag=se&keywords=solgar+methylcobalamin+b12&qid=1719131860&sprefix=%E2%80%A2%09Solgar+Methylcobalamin%2Caps%2C197&sr=8-1" className="external-link">Solgar Methylcobalamin:</a>Non-GMO, gluten-free, easy-to-swallow nuggets.</li>
        <li><a href="https://www.amazon.com/Garden-Life-Organics-B12-Vitamin/dp/B0CSD56QL9/ref=sr_1_1?crid=120FZ78OGWYYT&dib=eyJ2IjoiMSJ9.urq81Py_1qPRb7yQasqx4zGXE22p7vbVQTcnGfiC9Pp8Hr1bEDFxtIFPENNbdhB0dGPe8GgGvz2mOHedB4nvXW6VIvo0yr6C5HT8HV54PS-vsTqiEKJyfeY9ed-jp9ff7_QpHFwMepn9LiQC7mZXMzIy-ckwV-ccAw9vy00wbCg.rijYu1ptuwwlWlbW2Hf3GglFno9ZhgksAp9iy_kqpUo&dib_tag=se&keywords=%E2%80%A2+Garden+of+Life+B12+Spray&qid=1719131883&sprefix=garden+of+life+b12+spray%2Caps%2C185&sr=8-1" className="external-link">Garden of Life B12 Spray:</a>Organic, non-GMO, whole food-based spray.</li>
        <li><a href="https://www.amazon.com/Jarrow-Formulas-Pyridoxal-5-phosphate-Lozenges-Supports/dp/B01IJR5VM2/ref=sr_1_1?crid=2RZBV60Q5UJVY&dib=eyJ2IjoiMSJ9.6Ud6yz_BYOLx5Pk7f_XhJLKH03iKsPdoPUbFqymevpdtY9dEt1akhz57zod8AASVLt1TokbG1V5kh2Jm3tdfdrPVHpNCdLaSLJ4w-G8izGyqoKhmb25PkEdOR0-rnEEyngJOvpJdwBu0zRmfBrrzbvVAL09mypLwEZSwu4R4n6eHwRCQ6BgwGMzBYV5qYOKT9LQlJAsOLFfq4KxACTrev4U5eV9Qk7mJTpIevTzTcAfWX0iYNnlu0jn_dxRqlGhUkGfMhHh9CYqJ1dSQoI7RYjVA9-IbUANn4Pbahv9Q00A.Urq3OepJUSQhE5M82fEwwpp6Le-UF0yU-yQypDcl-5o&dib_tag=se&keywords=%E2%80%A2%2BJarrow%2BFormulas%2BMethyl%2BB12&qid=1719131899&sprefix=garden%2Bof%2Blife%2Bb12%2Bspray%2Caps%2C181&sr=8-1&th=1" className="external-link">Jarrow Formulas Methyl B12:</a>High bioavailability, quality ingredients, lozenges.</li>
        <li><a href="https://www.amazon.com/Biotics-Research-Lozenges-Vitamin-Lozenges/dp/B003PQRO0K/ref=sr_1_2?crid=SYNF7N3PUGQH&dib=eyJ2IjoiMSJ9.zj7yZGzZu6eydZyv5RCLZxpHz8aiHLKcPJ8-pC_ixWDs32gzxq3awW0-MOjvS47Z1oP8R0ygguKnP8FLTJ2Bujy5aQHaIGHRSA6HgGuH-mTam9dcol-KXjCGjCnHW0_3pnLawfKPVeu9gOnaLqZ_UovYQyiKKML_2BOIUz0rwQk6USbwdFTm7GMw5nwtqmOmbOeVUyxAnADEW1IRTlYYPVv1eRanCMkIdK1nuCeXYsw.m0qTnv4_vlKdPIEW-4Uw0wA0biXH5aCNhKoCVb_VYO0&dib_tag=se&keywords=%E2%80%A2+Thorne+Research+B12&qid=1719131936&sprefix=thorne+research+b12%2Caps%2C182&sr=8-2" className="external-link">Thorne Research B12:</a>Extensively tested, recommended by professionals, capsules.</li>
      </ul>
    </section>
    <section className="blog-page-section">
      <p className="in-out-label">Conclusion</p>
      <h2>Ενσωμάτωση της Συμπλήρωσης Β12 σε έναν Υγιεινό Φυτικό Τρόπο Ζωής</h2>
      <p>Για όσους υιοθετούν φυτική διατροφή, η διασφάλιση επαρκούς πρόσληψης βιταμίνης Β12 είναι απαραίτητη. Η συμπλήρωση Β12 καθίσταται αναπόσπαστο μέρος της καθημερινής διατροφής λόγω της έλλειψης φυσικών πηγών στη βίγκαν ή χορτοφαγική δίαιτα. Η σωστή ενσωμάτωσή της περιλαμβάνει την επιλογή κατάλληλης μορφής, τη διατήρηση μιας σταθερής ρουτίνας και την τακτική παρακολούθηση των επιπέδων μέσω επαγγελματικής καθοδήγησης.</p>
    </section>
  </div>
)
}
export default TestBlog;