import React from "react";

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container p-4 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
      <p className="text-sm text-gray-700 mb-4">
        Last updated: 2025-03-21
      </p>
      <p className="mb-4">
        These Terms of Service ("Terms") govern your use of the nutrition consultation services provided by
        e-nutritionist ("we," "us," "our") via our website. By accessing or using our services, you agree to comply with
        and be bound by these Terms.
      </p>

      <h2 className="font-semibold text-lg mt-4">1. Description of Services</h2>
      <p className="mb-4">
        We provide personalized nutrition consultations, meal planning, lifestyle coaching, and fitness guidance via
        online platforms, primarily through Google Meet and WhatsApp.
      </p>

      <h2 className="font-semibold text-lg mt-4">2. Booking & Payments</h2>
      <ul className="list-disc ml-8 mb-4">
        <li><strong>Initial Consultation:</strong> We offer a free introductory call (approx. 15 minutes).</li>
        <li><strong>Payments:</strong> Payments via Pay-by-link must be completed 24 hours before the session.</li>
      </ul>

      <h2 className="font-semibold text-lg mt-4">3. Cancellation & Rescheduling Policy</h2>
      <p className="mb-4">
        Appointments may be rescheduled or canceled up to 24 hours in advance without penalty.
      </p>

      <h2 className="font-semibold text-lg mt-4">4. Consultation Procedure & Communication</h2>
      <p className="mb-4">
        Consultations are conducted via Google Meet. Continuous support via WhatsApp.
      </p>

      <h2 className="font-semibold text-lg mt-4">5. Client Obligations</h2>
      <p className="mb-4">
        Provide accurate health-related information and update us on health changes.
      </p>

      <h2 className="font-semibold text-lg mt-4">6. Health & Medical Disclaimer</h2>
      <p className="mb-4">
        Our services do not replace medical advice. Consult your healthcare provider before beginning new plans.
      </p>

      <h2 className="font-semibold text-lg mt-4">7. Privacy & Data Security</h2>
      <p className="mb-4">
        All data is confidential and will not be shared without consent.
      </p>

      <h2 className="font-semibold text-lg mt-4">8. Intellectual Property</h2>
      <p className="mb-4">
        All provided content remains the intellectual property of e-nutritionist.
      </p>

      <h2 className="font-semibold text-lg mt-4">9. Refund Policy</h2>
      <p className="mb-4">
        Generally, no refunds after personalized plans are delivered.
      </p>

      <h2 className="font-semibold text-lg mt-4">10. Limitations of Liability</h2>
      <p className="mb-4">
        We are not liable for any health issues arising from using our services.
      </p>

      <h2 className="font-semibold text-lg mt-4">11. Termination of Services</h2>
      <p className="mb-4">
        We reserve the right to terminate services immediately if Terms are breached.
      </p>

      <h2 className="font-semibold text-lg mt-4">12. Amendments & Updates</h2>
      <p className="mb-4">
        We may update these Terms anytime. Updates will be published on our website.
      </p>

      <h2 className="font-semibold text-lg mt-4">13. Governing Law</h2>
      <p className="mb-4">
        These Terms are governed by the laws of Spain and subject to the jurisdiction of Madrid.
      </p>

      <h2 className="font-semibold text-lg mt-4">14. Contact Information</h2>
      <p className="mb-4">
        For any questions, requests, or complaints regarding these Terms:
      </p>
      <ul className="list-none ml-8 mb-4">
        <li><strong>e-nutritionist</strong></li>
        <li>Email: <a href="mailto:thimiosarvanitis@gmail.com" className="text-blue-500">thimiosarvanitis@gmail.com</a></li>
        <li>Phone: +34 613 497 305</li>
        <li>Address: Calle de Cañete 18, Madrid, Spain</li>
      </ul>

      <p className="text-sm text-gray-700">
        By using our services, you confirm that you have read, understood, and agree to these Terms of Service.
      </p>
    </div>
  );
};

export default TermsOfService;
