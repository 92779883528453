import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../context/LanguageContext"; // ✅ Import LanguageContext
import "./AdminBlogList.css";
import { API_BASE_URL } from '../../../config';

const AdminBlogList = () => {
  const navigate = useNavigate();
  const { selectedLanguage } = useLanguage(); // ✅ Get selected language from context
  const [blogs, setBlogs] = useState([]);

  // ✅ Fetch blogs based on selected language
  useEffect(() => {
    fetch(`${API_BASE_URL}/api/v1/blogs?language=${selectedLanguage}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Blogs:", data);  // Debug log
        if (data.blogs && Array.isArray(data.blogs)) {
          setBlogs(data.blogs);
        } else {
          setBlogs([]);  // Prevent crash if API returns unexpected data
        }
      })
      
      .catch((error) => console.error("Error fetching blogs:", error));
  }, [selectedLanguage]); // ✅ Re-run when language changes

  // ✅ Delete Blog Function (Now inside the component)
  const handleDelete = (slug) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      fetch(`${API_BASE_URL}/api/v1/blogs/${slug}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            alert("Blog deleted successfully");
            // Update the state to remove the deleted blog
            setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.slug !== slug));
          } else {
            alert("Failed to delete blog");
          }
        })
        .catch((error) => console.error("Error deleting blog:", error));
    }
  };

  return (
    <div className="admin-blog-list">
      <h1>📝 Blog Management</h1>
      <button className="create-btn" onClick={() => navigate("/admin-create-blog")}>
        ➕ Create a Blog
      </button>

      {/* ✅ Blog List */}
      <div className="blog-grid">
        {blogs.length > 0 ? (
          blogs.map((blog) => (
            <div key={blog.id} className="blog-card">
              <img
                src={`${API_BASE_URL}/images/${blog.image}.jpg`}
                alt={blog.title}
                className="blog-image"
              />
              <h2>{blog.title}</h2>
              <p><strong>Category:</strong> {blog.category}</p>
              <p><strong>Published:</strong> {blog.published_at ? new Date(blog.published_at).toLocaleDateString() : "Draft"}</p>
              <p><strong>Language:</strong> {blog.language}</p>
              <p><strong>Description:</strong> {blog.description}</p>

              <div className="blog-actions">
                <button onClick={() => navigate(`/admin-view-blog/${blog.slug}`)}>👁 View</button>
                <button onClick={() => navigate(`/admin-edit-blog/${blog.slug}`)}>✏ Edit</button>
                <button className="delete-btn" onClick={() => handleDelete(blog.slug)}>❌ Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p>No blogs found in this language.</p> // ✅ Show message when no blogs exist
        )}
      </div>
    </div>
  );
};

export default AdminBlogList;
