import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BlogEditor from "./contenteditor/blogeditor/BlogEditor"; // Import modal
import "./AdminBlogForm.css";
import { API_BASE_URL } from '../../../config';

const AdminCreateBlog = () => {
  const navigate = useNavigate();
  const [blog, setBlog] = useState({
    title: "",
    image: "",
    description: "",
    category: "",
    htmlcontent: "", // ✅ Store HTML content properly
    published_at: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    language: "en",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  // Handle form field changes
  const handleChange = (e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value });
  };

  // Form validation
  const validateForm = () => {
    if (!blog.title.trim()) {
      alert("Title is required.");
      return false;
    }
    if (!blog.image.trim()) {
      alert("Image URL is required.");
      return false;
    }
    if (!blog.description.trim()) {
      alert("Description is required.");
      return false;
    }
    if (!blog.category.trim()) {
      alert("Category is required.");
      return false;
    }
    if (!blog.htmlcontent.trim()) {
      alert("Blog content is required.");
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("adminToken");
    console.log("Token Retrieved:", token); // Debug log

    if (!token) {
      alert("Unauthorized: Please log in as admin.");
      return;
    }

    const blogData = {
      title: blog.title,
      image: blog.image,
      description: blog.description,
      category: blog.category,
      published_at: blog.published_at,
      metatitle: blog.meta_title,
      metadescription: blog.meta_description,
      metakeywords: blog.meta_keywords,
      language: blog.language,
      htmlcontent: blog.htmlcontent,
    };

    console.log("Submitting Blog Data:", blogData);
    setLoading(true); // Start loading

    try {
      console.log(blogData); // Log to verify the structure

      const response = await fetch(`${API_BASE_URL}/api/v1/blogs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ blog: blogData }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create blog");
      }

      alert("Blog created successfully");
      navigate("/admin-blog-list");
    } catch (error) {
      console.error("Error creating blog:", error);
      alert(`Error creating blog: ${error.message}`);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Open modal for content editor
  const openContentEditor = () => {
    setIsModalOpen(true);
  };

  // Close modal for content editor
  const closeContentEditor = () => {
    setIsModalOpen(false);
  };

  // Save HTML content from modal
  const saveHtmlContent = (htmlContent) => {
    setBlog((prevBlog) => ({ ...prevBlog, htmlcontent: htmlContent }));
  };

  return (
    <div className="admin-blog-form">
      <h1>Create Blog</h1>
      <form onSubmit={handleSubmit}>
        <label>Title:</label>
        <input type="text" name="title" value={blog.title} onChange={handleChange} required />

        <label>Image URL:</label>
        <input type="text" name="image" value={blog.image} onChange={handleChange} required />

        <label>Description:</label>
        <textarea name="description" value={blog.description} onChange={handleChange} required />

        <label>Category:</label>
        <input type="text" name="category" value={blog.category} onChange={handleChange} required />

        <label>Published Date:</label>
        <input type="date" name="published_at" value={blog.published_at} onChange={handleChange} />

        <label>Meta Title:</label>
        <input type="text" name="meta_title" value={blog.meta_title} onChange={handleChange} />

        <label>Meta Description:</label>
        <textarea name="meta_description" value={blog.meta_description} onChange={handleChange} />

        <label>Meta Keywords:</label>
        <input type="text" name="meta_keywords" value={blog.meta_keywords} onChange={handleChange} />

        <label>Language:</label>
        <select name="language" value={blog.language} onChange={handleChange}>
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="gr">Greek</option>
        </select>

        <button type="button" onClick={openContentEditor}>Create Blog Content</button>
        <button type="submit" disabled={loading}>
          {loading ? "Creating..." : "Create Blog"}
        </button>
      </form>

      {isModalOpen && (
        <BlogEditor 
          currentContent={blog.htmlcontent}
          onSave={saveHtmlContent}  // ✅ Make sure this function exists
          onClose={closeContentEditor}
        />
      )}
    </div>
  );
};

export default AdminCreateBlog;
