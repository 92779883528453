import React, { useEffect, useState } from "react";
import "./AdminNewsletters.css";
import { API_BASE_URL } from '../../../config';

const AdminNewsletters = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch subscribers when component mounts
  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/subscribers`);

        if (!response.ok) {
          throw new Error(`Error ${response.status}: Failed to fetch subscribers`);
        }

        const data = await response.json();
        setSubscribers(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscribers();
  }, []);

  return (
    <div className="admin-newsletters">
      <h2 className="admin-title">Manage Subscribers</h2>

      {loading ? (
        <p className="loading-text">Loading subscribers...</p>
      ) : error ? (
        <p className="error-text">{error}</p>
      ) : subscribers.length > 0 ? (
        <div className="subscribers-list">
          {subscribers.map((subscriber) => (
            <div key={subscriber.id} className="subscriber-card">
              <p className="subscriber-email">📧 {subscriber.email}</p>
              <p className="subscriber-date">
                Subscribed at:{" "}
                {subscriber.created_at
                  ? new Date(subscriber.created_at).toLocaleString()
                  : "Unknown"}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-subscribers-text">No subscribers found.</p>
      )}
    </div>
  );
};

export default AdminNewsletters;
