import PageHero from "../../components/pagehero/PageHero";
import ServicePlans from "./ServicePlans"; // ✅ Import ServicePlans component
import backgroundImg from "../../images/servicesimages/BackgroundTitleImage.jpg";

import "./Services.css";

const Services = () => {
  return (
    <>
      {/* Hero Section */}
      <PageHero
        image={backgroundImg}
        description="Learn more about our mission to promote a healthy lifestyle."
      />

      {/* Services Grid Section */}
      <section className="services-grid">
        <h2 className="service-plans-title">Available Service Plans</h2>
        <ServicePlans /> {/* ✅ Load dynamic service plans here */}
      </section>

      {/* Service Description Section */}
      <section className="services-info-container">
        <div className="services-info">
          <h2>How Our Services Work</h2>
          <p><strong>Important Information for Your Consultations</strong></p>

          <p>We offer a <strong>complimentary 15-minute introductory call</strong> before your first booking, allowing you to discuss your goals and find the perfect plan for your needs.</p>

          <p>All consultations take place via <strong>Google Meet.</strong><strong>Continuous support between sessions</strong> is available 24h via WhatsApp, ensuring you always have easy communication with us.</p>

          <p><strong>Payment & Scheduling:</strong> Payments must be made via <strong>Pay-by-link</strong> at least <strong>24 hours before</strong> your scheduled session. If payment is not received in time, the session will be <strong>automatically canceled.</strong></p>

          <p><strong>Cancellation & Rescheduling:</strong> Appointments may be rescheduled or canceled <strong>up to 24 hours in advance</strong> without additional fees.</p>
        </div>
      </section>
    </>
  );
};

export default Services;
