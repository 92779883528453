import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";

const Navbar = ({ isOpen, closeNavbar }) => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <nav className={`navbar ${isOpen ? "active" : ""}`}>
      <Link to="/" className={`nav-link ${isActive("/") ? "active" : ""}`} onClick={closeNavbar}>
        Home
      </Link>
      <Link to="/services" className={`nav-link ${isActive("/services") ? "active" : ""}`} onClick={closeNavbar}>
        Services
      </Link>
      <Link to="/blogs" className={`nav-link ${isActive("/blogs") ? "active" : ""}`} onClick={closeNavbar}>
        Blogs
      </Link>
      <Link to="/recipes" className={`nav-link ${isActive("/recipes") ? "active" : ""}`} onClick={closeNavbar}>
        Recipes
      </Link>
      <Link to="/about" className={`nav-link ${isActive("/about") ? "active" : ""}`} onClick={closeNavbar}>
        About
      </Link>
      <Link to="/contact" className={`nav-link ${isActive("/contact") ? "active" : ""}`} onClick={closeNavbar}>
        Contact
      </Link>
    </nav>
  );
};

export default Navbar;
