import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import "./Newsletter.css";
import { API_BASE_URL } from "../../../config";

// Import the translations JSON
import translations from "./newsletterTranslations.json";

const Newsletter = () => {
  const { selectedLanguage } = useLanguage();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const newsletterRef = useRef(null);

  // Get the translation object for the selected language
  const t = translations[selectedLanguage] || translations["en"]; // Fallback to English if translation is not available

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      { threshold: 0.3 }
    );

    if (newsletterRef.current) {
      observer.observe(newsletterRef.current);
    }

    return () => {
      if (newsletterRef.current) {
        observer.unobserve(newsletterRef.current);
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Reset the message before submitting

    if (email) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/subscribers`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subscriber: { email } }), // Send email in the correct format
        });

        const data = await response.json();

        if (response.ok) {
          setMessage(t.successMessage); // Show success message if the subscription was successful
          setEmail(""); // Clear email input field
        } else {
          setMessage(data.error || t.errorMessage); // Show error if something goes wrong
        }
      } catch (error) {
        setMessage(t.generalErrorMessage); // Show an error message in case of a network/API issue
      }
    } else {
      setMessage(t.invalidEmailMessage); // Show error if the email is invalid
    }
  };

  return (
    <div ref={newsletterRef} className="newsletter lazy-load">
      <h2 className="newsletter-title">{t.title}</h2>
      <p className="newsletter-description">{t.description}</p>
      <form onSubmit={handleSubmit} className="newsletter-form">
        <input
          type="email"
          placeholder={t.placeholder}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="newsletter-input"
          required
          aria-label="Email Address"
        />
        <button type="submit" className="newsletter-button" aria-label="Subscribe">
          {t.subscribeButton}
        </button>
      </form>
      {message && <p className="newsletter-message">{message}</p>}
    </div>
  );
};

export default Newsletter;
