import PageHero from "../../components/pagehero/PageHero";
import backgroundImg from "../../images/homeimages/hero-section/background.jpg";
import { useLanguage } from "../../context/LanguageContext";
import { ArrayOfConceptsEn, ArrayOfConceptsEs, ArrayOfConceptsGr } from "./conceptsection/ArrayOfConcepts";
import ConceptsSection from "./conceptsection/ConceptsSection";
import ServicesPreview from "./servicespreview/ServicesPreview";
import InstagramPost from "./instagrampost/InstagramPost";

const Home = () => {
  const { selectedLanguage } = useLanguage();

  const conceptsArray =
    selectedLanguage === "es" ? ArrayOfConceptsEs :
    selectedLanguage === "gr" ? ArrayOfConceptsGr :
    ArrayOfConceptsEn;

  return (
    <>
      <PageHero
        image={backgroundImg}
        description="Empowering individuals to optimize their nutrition for improved health, performance, and overall well-being through personalized coaching, meal plans, and digital education."
      />
      <ConceptsSection concepts={conceptsArray} />
      <ServicesPreview />

      {/* Instagram Section */}
      <div className="instagram-posts-container">
        <h2>Check out our latest Instagram posts</h2>
        <InstagramPost />
      </div>
    </>
  );
};

export default Home;
