import React, { useState, useEffect } from "react";
import HtmlCustomEditor from "./HtmlCustomEditor";

const BlogEditor = ({ currentContent, onSave, onClose }) => {
  const [htmlContent, setHtmlContent] = useState(""); // Store raw HTML content
  const [isEditorOpen, setEditorOpen] = useState(false);

  // Sync initial content when the component mounts or updates
  useEffect(() => {
    setHtmlContent(currentContent);
  }, [currentContent]);

  // Function to save the HTML content
  const handleSaveHtml = (content) => {
    const cleanedContent = content.trim();  // Removes leading/trailing spaces/newlines
    console.log("Saved HTML content:", cleanedContent);
    setHtmlContent(cleanedContent); // Update local state
    
    if (typeof onSave === "function") {  // ✅ Check if onSave is a function
      onSave(cleanedContent); // Send updated content to parent
    } else {
      console.error("Error: onSave is not a function", onSave);
    }
  };
  
  

  // Toggle the editor visibility
  const toggleEditor = () => setEditorOpen(!isEditorOpen);

  return (
    <div>
      <h1>Blog Editor</h1>
      <button onClick={toggleEditor}>
        {isEditorOpen ? "Close HTML Editor" : "Open HTML Editor"}
      </button>

      {isEditorOpen && (
        <HtmlCustomEditor
          content={htmlContent}  // Pass initial content if any
          onSave={handleSaveHtml}
          onClose={toggleEditor}
        />
      )}
    </div>
  );
};

export default BlogEditor;
