import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer'; // Intersection observer for animation
import { useLanguage } from '../../../context/LanguageContext'; // ✅ Language context
import './servicepreview.css';
import { API_BASE_URL } from '../../../config';

const ServicesPreview = () => {
  const { selectedLanguage } = useLanguage(); // ✅ Get the current language
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const sliderRef = useRef(null); // ✅ Ref for carousel
  const { ref: titleRef, inView } = useInView({ threshold: 0.1 }); // ✅ Trigger animation when visible

  // ✅ Fetch services based on the selected language
  const fetchServices = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/service_plans?language=${selectedLanguage}`);
      if (!response.ok) throw new Error('Failed to fetch services');
      const data = await response.json();
      setServices(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  // ✅ Carousel settings
  const sliderSettings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        { breakpoint: 1040, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 500, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ],
    }),
    []
  );

  // ✅ Auto-slide every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) sliderRef.current.slickNext();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <div>Loading services...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className='carusel-section-services'>
      {/* ✅ Title animation */}
      <h2 className={`animated-title ${inView ? 'animate' : ''}`} ref={titleRef}>
        {selectedLanguage === "es"
          ? "Explora Nuestros Servicios Especiales para Ti"
          : selectedLanguage === "gr"
          ? "Ανακαλύψτε τις Υπηρεσίες μας που Σχεδιάστηκαν για Εσάς"
          : "Explore Our Exceptional Services Designed Just for You"}
      </h2>

      {/* ✅ Service Carousel */}
      <Slider ref={sliderRef} {...sliderSettings}>
        {services.map((service) => (
          <div className='service-container-outer' key={service.id}>
            <div className='service-container-inner'>
              <h2 className='title-of-service'>{service.title}</h2>
              <p className='description-of-service'>{service.description}</p>
              <Link to='/services' className='go-button'>
                {selectedLanguage === "es"
                  ? "Ir al Servicio"
                  : selectedLanguage === "gr"
                  ? "Πήγαινε στην Υπηρεσία"
                  : "Go To Service"}
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ServicesPreview;
