import React, { useState } from "react";
import ContactInfo from "./ContactInfo"
import ContactForm from "./ContactForm"
import LocationSection from "./LocationSection";

import PageHero from "../../components/pagehero/PageHero";
import img from "../../images/contactusimages/BackgroundTitleImage.jpg"
import "./Contact.css";

const Contact = () => {

  return (
    <>
      <PageHero
        image={img}
        title="Contact Us"
      />

      <div className="contact-container">
       <ContactInfo />
       <ContactForm />
      </div>

      <LocationSection />
    </>
  );
};

export default Contact;