import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminDashboard.css";
import { API_BASE_URL } from "../../config";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const verifyAdmin = async () => {
      const token = localStorage.getItem("adminToken");
      if (!token) {
        navigate("/admin-login");
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/admin/current`, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          const data = await response.json();
          setError(data.error || "Unauthorized access.");
          localStorage.removeItem("adminToken");
          navigate("/admin-login");
        }
      } catch (error) {
        setError("Network error. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    verifyAdmin();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    navigate("/admin-login");
  };

  if (loading) return <p>Loading dashboard...</p>;

  return (
    <div className="admin-dashboard">
      <h1>Welcome, Admin!</h1>

      {error && <p className="error">{error}</p>}

      {/* Navigation Menu */}
      <nav className="admin-nav">
        <button onClick={() => navigate("/admin-view-service-plans")}>📋 Service Plans</button>
        <button onClick={() => navigate("/admin-blog-list")}>📝 Blogs</button>
        <button onClick={() => navigate("/admin-recipes")}>🍽️ Recipes</button>
        <button onClick={() => navigate("/admin-newsletters")}>📧 Newsletters</button>
        <button onClick={() => navigate("/admin-view-bookings")}>📅 Bookings</button>
      </nav>

      {/* Dashboard Content */}
      <div className="dashboard-content">
        <p>Select a section from the menu to manage your website content.</p>
      </div>

      {/* Logout Button */}
      <button onClick={handleLogout} className="logout-btn">🚪 Logout</button>
    </div>
  );
};

export default AdminDashboard;
