import React, { useEffect, useRef } from "react";
import "./AboutJourney.css";

// Import images
import img1 from "../../../images/aboutimages/sectiontwo/background1.jpg";
import img2 from "../../../images/aboutimages/sectiontwo/background2.webp";
import img3 from "../../../images/aboutimages/sectiontwo/background3.jpg";
import img4 from "../../../images/aboutimages/sectiontwo/background4.jpg";
import img5 from "../../../images/aboutimages/sectiontwo/background5.jpg";

const AboutJourney = () => {
  const journeySteps = [
    { imgSrc: img1, title: "Educational and Professional Background", description: "My journey in nutrition began with a Bachelor's degree in Science from the University of Vienna, where I developed a strong foundation in health and wellness. Later, I earned a Master's in Nutrition from UCM Madrid, followed by specialized training in Football Nutrition." },
    { imgSrc: img2, title: "Specialization in Sports Nutrition and Fitness", description: " Specializing in athlete nutrition, we provide tailored solutions for those looking to enhance their sports performance and muscle gain, while also supporting a broad audience in achieving their health and fitness goals." },
    { imgSrc: img3, title: "Personal Philosophy and Approach", description: "Every health journey is unique, and I believe in crafting personalized nutrition and fitness plans that align with your specific goals, lifestyle, and aspirations. Whether you're looking to enhance athletic performance, build muscle, improve overall well-being, or develop a sustainable approach to nutrition, I’m here to guide you with science-backed, tailored strategies that work for you" },
    { imgSrc: img4, title: "My Personal Transformation", description: "Having personally lost over 40kg through dedicated workouts and optimized nutrition, I understand the challenges and victories of a health journey. Now, I use my experience and expertise to help others achieve lasting success, whether their goal is weight loss, muscle gain, or improved overall well-being." },
    { imgSrc: img5, title: "Your Partner in Health and Performance", description: "At e-nutritionist, I blend professional expertise with personal experience to create tailored nutrition and fitness plans that empower you to achieve your health, wellness, and performance goals. Whether you're an athlete striving for peak performance or someone looking to improve overall well-being, I provide science-backed strategies to support your journey"}];

  const stepsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      { threshold: 0.2 }
    );

    stepsRef.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      stepsRef.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <section className="about-journey">
      {journeySteps.map((step, index) => (
        <div
          key={index}
          ref={(el) => (stepsRef.current[index] = el)}
          className="journey-step lazy-load"
        >
          <img src={step.imgSrc} alt={step.title} className="journey-image" />
          <div className="journey-text">
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default AboutJourney;
