import React, { useEffect, useState } from "react";
import { API_BASE_URL } from '../../config';

const BookingCalendar = ({ selectedDate, setSelectedDate }) => {
  const [disabledDates, setDisabledDates] = useState([]);

  useEffect(() => {
    // Fetch unavailable dates from the backend API
    fetch(`${API_BASE_URL}/bookings/unavailable_dates`)
      .then((res) => res.json())
      .then((data) => setDisabledDates(data.dates || [])) // Adjust based on the actual structure of the response
      .catch((err) => console.error("Error fetching unavailable dates:", err));
  }, []);

  // Function to check if a date is disabled
  const isDateDisabled = (date) => {
    return disabledDates.includes(date);
  };

  return (
    <input
      type="date"
      value={selectedDate}
      onChange={(e) => setSelectedDate(e.target.value)}
      min={new Date().toISOString().split("T")[0]} // Disable past dates
      // Disable dates based on `disabledDates` array
      onInput={(e) => {
        const inputDate = e.target.value;
        if (isDateDisabled(inputDate)) {
          e.target.setCustomValidity("This date is unavailable.");
        } else {
          e.target.setCustomValidity(""); // Reset error if valid
        }
      }}
    />
  );
};

export default BookingCalendar;
