import React from "react";
import "./BurgerMenu.css";

const BurgerMenu = ({ isOpen, toggleBurger }) => {
  return (
    <div
      className={`burger-button ${isOpen ? "burger-active" : ""}`}
      onClick={toggleBurger}
    >
      <div className="line"></div>
      <div className="line"></div>
      <div className="line"></div>
    </div>
  );
};

export default BurgerMenu;
