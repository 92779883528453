import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../config";
import "./ViewRecipe.css";

const ViewRecipe = () => {
  const { slug } = useParams();
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/recipes/${slug}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` },
        });

        if (response.ok) {
          const data = await response.json();
          setRecipe(data);
        } else {
          setError("Failed to load recipe.");
        }
      } catch (error) {
        setError("Network error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchRecipe();
  }, [slug]);

  if (loading) return <p>Loading recipe...</p>;
  if (error) return <p className="error">{error}</p>;

  // Only render the recipe content if it exists
  if (!recipe) {
    return <p>Recipe not found.</p>;
  }

  return (
    <div className="view-recipe">
      <div className="recipe-header">
        <h1>{recipe.title}</h1>
        {recipe.image && <img src={recipe.image} alt={recipe.title} className="recipe-image" />}
        <p className="category"><strong>Category:</strong> {recipe.category}</p>
        <p className="meta-info">
          <strong>Published on:</strong> {new Date(recipe.published_at).toLocaleDateString()}
        </p>
      </div>

      <div className="recipe-description">
        <h2>Description</h2>
        <p>{recipe.description}</p>
      </div>

      <div className="recipe-ingredients">
        <h2>Ingredients</h2>
        <ul>
          {recipe.ingredients && recipe.ingredients.map((ingredient, index) => (
            <li key={index}>
              <strong>{ingredient.name}</strong>
            </li>
          ))}
        </ul>
      </div>

      <div className="recipe-instructions">
        <h2>Instructions</h2>
        <ol>
          {recipe.instructions && recipe.instructions.map((instruction, index) => (
            <li key={index}>
              <strong>Step {instruction.step_number}:</strong> {instruction.step}
              {instruction.note && <p><em>{instruction.note}</em></p>}
            </li>
          ))}
        </ol>
      </div>

      {/* New Section for Valuable Information */}
      <div className="recipe-valuable-info">
        <h2>Valuable Information</h2>
        <p><strong>Duration:</strong> {recipe.valuable_info?.duration || "Not specified"}</p>
        <p><strong>Difficulty:</strong> {recipe.valuable_info?.difficulty || "Not specified"}</p>
        <p><strong>Servings:</strong> {recipe.valuable_info?.persons || "Not specified"}</p>
      </div>
    </div>
  );
};

export default ViewRecipe;
