import React, { useEffect, useRef } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import quickLinksTranslations from "./translations";
import "./QuickLinks.css";

const QuickLinks = () => {
  const { selectedLanguage } = useLanguage();
  const translation = quickLinksTranslations[selectedLanguage];

  const links = [
    {
      title: translation.policies,
      items: [
        { name: translation.termsOfService, path: "/terms-of-service" },
        { name: translation.privacyPolicy, path: "/privacy-policy" },
        { name: translation.cookiesPolicy, path: "/cookies-policy" },
      ],
    },
    {
      title: translation.pages,
      items: [
        { name: translation.services, path: "/services" },
        { name: translation.blogs, path: "/blogs" },
        { name: translation.about, path: "/about" },
        { name: translation.contact, path: "/contactus" },
      ],
    },
    {
      title: translation.contactInfo,
      items: [
        { name: translation.email, path: "mailto:thimiosarvanitis@gmail.com" },
        { name: translation.phone, path: "tel:+34613497305" },
        { name: translation.address, path: "#" },
      ],
    },
  ];

  const titleRefs = useRef([]);
  const itemRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
            entry.target.classList.remove("lazy-load"); // ✅ Remove class to prevent re-triggering
          }
        });
      },
      { threshold: 0.3 }
    );

    // ✅ Copy refs into variables to ensure correct cleanup
    const currentTitleRefs = [...titleRefs.current];
    const currentItemRefs = [...itemRefs.current];

    currentTitleRefs.forEach((el) => el && observer.observe(el));
    currentItemRefs.forEach((el) => el && observer.observe(el));

    return () => {
      currentTitleRefs.forEach((el) => el && observer.unobserve(el));
      currentItemRefs.forEach((el) => el && observer.unobserve(el));
    };
  }, []);

  return (
    <section className="quick-links-container">
      {links.map((link, sectionIndex) => (
        <div key={link.title} className="quick-links-section">
          <h3
            ref={(el) => (titleRefs.current[sectionIndex] = el)}
            className="quick-links-title lazy-load"
          >
            {link.title}
          </h3>
          <ul>
            {link.items.map((item, itemIndex) => (
              <li
                key={itemIndex}
                ref={(el) => (itemRefs.current[sectionIndex * 10 + itemIndex] = el)}
                className="quick-link-item lazy-load"
              >
                <a href={item.path}>{item.name}</a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  );
};

export default QuickLinks;
