import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../config";
import "./AdminRecipes.css";

const AdminRecipes = () => {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isEditingBlocked, setIsEditingBlocked] = useState(true);  // Flag to control whether edit button is disabled
  const navigate = useNavigate();
  const selectedLanguage = "en"; // For now, assuming English language, can change based on language selection logic

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/recipes?language=${selectedLanguage}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` },
        });

        if (response.ok) {
          const data = await response.json();
          setRecipes(data); // Assuming the response returns a list of recipes
        } else {
          setError("Failed to load recipes.");
        }
      } catch (err) {
        setError("Network error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchRecipes();
  }, [selectedLanguage]);

  const handleView = (slug) => {
    navigate(`/admin-recipes/view/${slug}`);
  };

  const handleEdit = (slug) => {
    navigate(`/admin-recipes/edit/${slug}`);
  };

  const handleDelete = async (slug) => {
    const confirmation = window.confirm("Are you sure you want to delete this recipe?");
    if (!confirmation) return;

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/recipes/${slug}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` },
      });

      if (response.ok) {
        setRecipes(recipes.filter((recipe) => recipe.slug !== slug));
        alert("Recipe deleted successfully.");
      } else {
        alert("Failed to delete the recipe.");
      }
    } catch (error) {
      console.error("Error deleting recipe:", error);
      alert("Network error while deleting the recipe.");
    }
  };

  return (
    <div className="admin-recipes-container">
      <h1>Manage Recipes</h1>
      {error && <p className="error">{error}</p>}
      <button className="create-recipe-btn" onClick={() => navigate("/admin-recipes/create")}>
        Create a Recipe
      </button>
      {loading ? (
        <p>Loading recipes...</p>
      ) : (
        <div className="recipes-list">
          {recipes.length > 0 ? (
            recipes.map((recipe) => (
              <div key={recipe.slug} className="recipe-card">
                <h3>{recipe.title}</h3>
                <button onClick={() => handleView(recipe.slug)}>View</button>
                <button
                  onClick={() => handleEdit(recipe.slug)}
                >
                  Edit
                </button>
                <button onClick={() => handleDelete(recipe.slug)}>Delete</button>
              </div>
            ))
          ) : (
            <p>No recipes available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminRecipes;
