import React from "react";
import "./PageHero.css";

const PageHero = ({ image, title, description }) => {
  return (
    <section className={`page-hero ${description ? "with-description" : "no-description"}`}>
      <div
        className="page-hero-bg"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="overlay"></div>
      <div className="page-hero-content">
        {title && <h1>{title}</h1>}
        {description && <p>{description}</p>}
      </div>
    </section>
  );
};

export default PageHero;