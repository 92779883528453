import { useEffect, useState } from "react";
import "./DarkModeToggle.css";
import sunIcon from "../../images/headerimages/sun.svg";  // ☀️ Light mode icon
import moonIcon from "../../images/headerimages/moon.svg"; // 🌙 Dark mode icon

const DarkModeToggle = () => {
  // Check saved theme in localStorage (default: dark)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme") !== "light"
  );

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.classList.add("dark-mode");
      root.classList.remove("light-mode");
    } else {
      root.classList.add("light-mode");
      root.classList.remove("dark-mode");
    }
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <div className="dark-mode-toggle" onClick={toggleDarkMode}>
      <div className={`toggle-bar ${isDarkMode ? "dark" : "light"}`}>
        <img
          src={isDarkMode ? moonIcon : sunIcon}
          alt={isDarkMode ? "Dark Mode" : "Light Mode"}
          className="toggle-icon"
        />
      </div>
    </div>
  );
};

export default DarkModeToggle;
