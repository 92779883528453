import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RenderContent from "./RenderContent"; 
import "./AdminViewBlog.css"; // ✅ Custom CSS for modern styling
import { API_BASE_URL } from '../../../config';


const AdminViewBlog = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/v1/blogs/${slug}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Blog not found");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched blog data:", data);
        setBlog(data);
      })
      .catch((error) => {
        console.error("Error fetching blog:", error);
      });
  }, [slug]);

  if (!blog) return <p className="loading-text">Loading...</p>;

  return (
    <div className="admin-view-blog">
      {/* ✅ Blog Header */}
      <div className="blog-header">
        <h1>{blog.title}</h1>
        <p className="blog-category">{blog.category} | {new Date(blog.published_at).toLocaleDateString()}</p>
      </div>

      {/* ✅ Blog Image */}
      {blog.image && (
        <div className="blog-image-container">
          <img src={`${API_BASE_URL}/images/${blog.image}.jpg`} alt={blog.title} className="blog-image" />
        </div>
      )}

      {/* ✅ Blog Content Section */}
      <div className="blog-content-container">
        <h2>Blog Content</h2>
        <div className="blog-content">
          {blog.htmlcontent ? <RenderContent content={blog.htmlcontent} /> : <p>No content available.</p>}
        </div>
      </div>

      {/* ✅ Blog Details Section */}
      <div className="blog-details">
        <h2>Blog Details</h2>
        <p><strong>Slug:</strong> {blog.slug}</p>
        <p><strong>Language:</strong> {blog.language}</p>
        <p><strong>Description:</strong> {blog.description}</p>
        <p><strong>Created At:</strong> {new Date(blog.created_at).toLocaleString()}</p>
        <p><strong>Updated At:</strong> {new Date(blog.updated_at).toLocaleString()}</p>
      </div>

      {/* ✅ SEO Metadata */}
      <div className="blog-meta">
        <h2>SEO Metadata</h2>
        <p><strong>Meta Title:</strong> {blog.meta_title}</p>
        <div><strong>Meta Description:</strong> <pre>{blog.meta_description}</pre></div>  {/* Use <div> instead of <p> */}
        <div><strong>Meta Keywords:</strong> <pre>{blog.meta_keywords}</pre></div>  {/* Use <div> instead of <p> */}
      </div>
    </div>
  );
};

export default AdminViewBlog;
