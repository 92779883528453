import React from "react";

const CookiesPolicy = () => {
  return (
    <div className="cookies-policy-container p-4 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Cookies Policy</h1>
      <p className="text-sm text-gray-700 mb-4">
        Last updated: 2025-03-21
      </p>
      <p className="mb-4">
        This Cookies Policy explains how e-nutritionist ("we", "us", "our") uses cookies and similar tracking
        technologies when you visit our website.
      </p>

      <h2 className="font-semibold text-lg mt-4">1. What Are Cookies?</h2>
      <p className="mb-4">
        Cookies are small text files stored on your device when you visit websites, helping enhance user experience
        by remembering your preferences.
      </p>

      <h2 className="font-semibold text-lg mt-4">2. How We Use Cookies</h2>
      <ul className="list-disc ml-8 mb-4">
        <li><strong>Functional Cookies:</strong> Essential for providing core features of our website.</li>
        <li><strong>Analytical Cookies:</strong> Used for understanding how visitors interact with our site, helping us improve
          performance and user experience.</li>
        <li><strong>Marketing Cookies:</strong> Occasionally used to deliver personalized advertising based on your browsing behavior.</li>
      </ul>

      <h2 className="font-semibold text-lg mt-4">3. Types of Cookies We Use</h2>
      <ul className="list-disc ml-8 mb-4">
        <li><strong>Session Cookies:</strong> Temporary cookies deleted after your session.</li>
        <li><strong>Persistent Cookies:</strong> Remain on your device for a defined period or until manually deleted.</li>
      </ul>

      <h2 className="font-semibold text-lg mt-4">4. Managing Your Cookies</h2>
      <p className="mb-4">
        You can adjust cookie settings in your web browser. Note, disabling cookies may impact your browsing
        experience.
      </p>

      <h2 className="font-semibold text-lg mt-4">5. Third-Party Cookies</h2>
      <p className="mb-4">
        We may use third-party services like analytics providers, which may also place cookies on your device.
        These third parties have their own privacy policies.
      </p>

      <h2 className="font-semibold text-lg mt-4">6. Updates to This Policy</h2>
      <p className="mb-4">
        We may periodically update this policy. Any changes will be posted on our website.
      </p>

      <h2 className="font-semibold text-lg mt-4">7. Contact Information</h2>
      <p className="mb-4">
        For any questions about this Cookies Policy:
      </p>
      <ul className="list-none ml-8 mb-4">
        <li><strong>e-nutritionist</strong></li>
        <li>Email: <a href="mailto:thimiosarvanitis@gmail.com" className="text-blue-500">thimiosarvanitis@gmail.com</a></li>
        <li>Phone: +34 613 497 305</li>
        <li>Address: Calle de Cañete 18, Madrid, Spain</li>
      </ul>

      <p className="text-sm text-gray-700">
        By continuing to browse our site, you acknowledge and agree to our use of cookies.
      </p>
    </div>
  );
};

export default CookiesPolicy;
