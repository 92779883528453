import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./ContactInfo.css";

const ContactInfo = () => {
  return (
      <div className="contact-info">
        <h2>Get in Touch</h2>
        <p>Use our contact form for inquiries or reach us directly below.</p>
        <div className="contact-details">
          <div className="contact-item">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            <h4>thimiosarvanitis@gmail.com</h4>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faWhatsapp} className="icon whatsapp" />
            <h4>+34 613 497 305</h4>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faLocationDot} className="icon location" />
            <h4>Calle Cañete 18, Madrid, Spain</h4>
          </div>
        </div>
      </div>
  );
};

export default ContactInfo;