import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateServicePlan.css";
import { API_BASE_URL } from "../../../config";

// Reusable LanguageSection component
const LanguageSection = ({
  lang,
  servicePlan,
  onChange,
  onAddFeature,
  onUpdateFeature,
  onRemoveFeature,
}) => (
  <div key={lang} className="language-section">
    <h3>{lang.toUpperCase()}</h3>

    <label>Title:</label>
    <input
      type="text"
      value={servicePlan.title}
      onChange={(e) => onChange(lang, "title", e.target.value)}
      required
    />

    <label>Description:</label>
    <textarea
      value={servicePlan.description}
      onChange={(e) => onChange(lang, "description", e.target.value)}
      required
    />

    <div className="features-section">
      <h4>Features:</h4>
      {servicePlan.features.map((feature, index) => (
        <div key={index} className="feature-item">
          <input
            type="text"
            value={feature.name}
            onChange={(e) => onUpdateFeature(lang, index, e.target.value)}
            placeholder="Feature name"
            required
          />
          <button type="button" onClick={() => onRemoveFeature(lang, index)}>
            ❌
          </button>
        </div>
      ))}
      <button type="button" onClick={() => onAddFeature(lang)}>
        ➕ Add Feature
      </button>
    </div>
  </div>
);

const CreateServicePlan = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchedToken = localStorage.getItem("adminToken");
    if (!fetchedToken) {
      console.log("Token not found. Redirecting to login.");
      navigate("/admin-login"); // Redirect to login if token is not found
    } else {
      setToken(fetchedToken);
    }
  }, [navigate]);

  // State for service plans
  const [servicePlans, setServicePlans] = useState({
    en: { title: "", description: "", features: [] },
    es: { title: "", description: "", features: [] },
    gr: { title: "", description: "", features: [] },
    price: "",
    order: "",
  });

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const getToken = () => localStorage.getItem("adminToken");

  const handleChange = (lang, field, value) => {
    setServicePlans((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], [field]: value },
    }));
  };

  const handleGlobalChange = (field, value) => {
    setServicePlans((prev) => ({ ...prev, [field]: value }));
  };

  const addFeature = (lang) => {
    setServicePlans((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], features: [...prev[lang].features, { name: "" }] },
    }));
  };

  const updateFeature = (lang, index, value) => {
    setServicePlans((prev) => {
      const updatedFeatures = [...prev[lang].features];
      updatedFeatures[index].name = value;
      return { ...prev, [lang]: { ...prev[lang], features: updatedFeatures } };
    });
  };

  const removeFeature = (lang, index) => {
    setServicePlans((prev) => {
      const updatedFeatures = prev[lang].features.filter((_, i) => i !== index);
      return { ...prev, [lang]: { ...prev[lang], features: updatedFeatures } };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = getToken();
    if (!token) {
      console.log("Token not found. Redirecting to login.");
      navigate("/admin-login"); // Redirect to login if token is not found
      return;
    }

    const servicePlanDataArray = ["en", "es", "gr"].map((lang) => ({
      title: servicePlans[lang].title,
      price: parseInt(servicePlans.price),
      order: parseInt(servicePlans.order),
      description: servicePlans[lang].description,
      language: lang,
      features_attributes: servicePlans[lang].features
        .filter((f) => f.name.trim() !== "")
        .map((f) => ({
          name: f.name,
        })),
    }));

    try {
      setLoading(true);
      setMessage("Submitting service plans...");

      const response = await fetch(`${API_BASE_URL}/api/v1/service_plans`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ service_plans: servicePlanDataArray }),
      });

      if (response.status === 401) {
        // If 401, token is invalid or missing
        localStorage.removeItem("adminToken");
        navigate("/admin-login"); // Redirect to login if token is invalid
        return;
      }

      const result = await response.json();

      if (response.ok) {
        setMessage("All service plans created successfully!");

        // Reset the form after successful submission
        setServicePlans({
          en: { title: "", description: "", features: [] },
          es: { title: "", description: "", features: [] },
          gr: { title: "", description: "", features: [] },
          price: "",
          order: "",
        });

        navigate("/admin-view-service-plans");
      } else {
        setMessage(`Some service plans failed: ${result.error}`);
      }
    } catch (error) {
      setMessage("Failed to create service plans. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-service-plan">
      <h2>Create a New Service Plan</h2>

      <form onSubmit={handleSubmit}>
        <label>Price (€):</label>
        <input
          type="number"
          value={servicePlans.price}
          onChange={(e) => handleGlobalChange("price", e.target.value)}
          required
        />

        <label>Order:</label>
        <input
          type="number"
          value={servicePlans.order}
          onChange={(e) => handleGlobalChange("order", e.target.value)}
          required
        />

        {["en", "es", "gr"].map((lang) => (
          <LanguageSection
            key={lang}
            lang={lang}
            servicePlan={servicePlans[lang]}
            onChange={handleChange}
            onAddFeature={addFeature}
            onUpdateFeature={updateFeature}
            onRemoveFeature={removeFeature}
          />
        ))}

        <button type="submit" disabled={loading}>
          {loading ? "Saving..." : "Save Service Plans"}
        </button>
      </form>

      {message && <p className="status-message">{message}</p>}
    </div>
  );
};

export default CreateServicePlan;
