import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./BlogDetails.css";
import PageHero from "../../components/pagehero/PageHero";
import RenderContent from "../admin/adminblogs/RenderContent";
import { API_BASE_URL } from '../../config';

const DEFAULT_BG = `${API_BASE_URL}/images/blogs/BackgroundTitleImage.jpg`;

const BlogDetails = () => {
  const { slug } = useParams(); 
  const [blog, setBlog] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(DEFAULT_BG); // Default background

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/v1/blogs/${slug}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Blog not found");
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          setBlog(data);
          // Dynamically set the background image from the blog's image URL
          if (data.image) {
            setBackgroundImage(`${API_BASE_URL}/${data.image}/profile-pic.webp`);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Blog not found.");
        setLoading(false);
      });
  }, [slug]);  // Runs when the slug changes
  
  

  // Fetch related blogs
  useEffect(() => {
    if (blog) {
      fetch(`${API_BASE_URL}/api/v1/blogs?language=${blog.language}`)
        .then((res) => res.json())
        .then((data) => {
          const related = data.blogs.filter((b) => b.slug !== blog.slug);
          setRelatedBlogs(related);
        })
        .catch((err) => {
          setError("Failed to load related posts.");
        });
    }
  }, [blog]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">{error}</p>;
  if (!blog) return null;

  return (
    <>
      {/* Pass the dynamically loaded background image */}
      <PageHero title={blog.title} image={backgroundImage} />

      <div className="single-blog-container">
        {blog.htmlcontent ? (
          <RenderContent content={blog.htmlcontent} />
        ) : (
          <p>No content available.</p>
        )}

        <div className="related-posts">
          <h3>Related Posts</h3>
          <div className="related-posts-list">
            {relatedBlogs.length > 0 ? (
              relatedBlogs.map((post) => (
                <div key={post.id} className="related-post">
                  <img
                    src={`${API_BASE_URL}/images/${post.image}.jpg`} // Assuming the related post images are located here
                    alt={post.title}
                    className="related-post-image"
                  />
                  <h4>{post.title}</h4>
                  <p>{post.description}</p>
                  <a href={`/blogs/${post.slug}`} className="read-more">
                    Read more
                  </a>
                </div>
              ))
            ) : (
              <p>No related posts available.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
