import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LanguageProvider } from "./context/LanguageContext";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import Services from "./pages/services/Services";
import Blogs from "./pages/blogs/Blogs";
import BlogDetails from "./pages/blogs/BlogDetails";
import TestBlog from "./pages/blogs/TestBlog";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import BookConsultation from "./pages/book-consultation/BookConsultation";
import Recipes from "./pages/recipes/Recipes";
import FullRecipe from "./pages/recipes/FullRecipe";

// Import policy components
import TermsOfService from "./pages/policies/TermsOfService";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";
import CookiesPolicy from "./pages/policies/CookiesPolicy";

// Admin Features
import ProtectedRoute from "./components/protectedroute/ProtectedRoute";
import AdminLogin from "./pages/admin/AdminLogin";
import AdminDashboard from "./pages/admin/AdminDashboard";

// Admin Sections
import ServicePlansAdmin from "./pages/admin/adminservicesplans/ServicePlansAdmin";
import CreateServicePlan from "./pages/admin/adminservicesplans/CreateServicePlan";
import EditServicePlan from "./pages/admin/adminservicesplans/EditServicePlan";
import BookingsAdmin from "./pages/admin/adminbookings/BookingsAdmin";
import AdminNewsletters from "./pages/admin/adminnewsletters/AdminNewsletters";
import AdminBlogList from "./pages/admin/adminblogs/AdminBlogList";
import AdminViewBlog from "./pages/admin/adminblogs/AdminViewBlog";
import AdminEditBlog from "./pages/admin/adminblogs/AdminEditBlog";
import AdminCreateBlog from "./pages/admin/adminblogs/AdminCreateBlog";

// Admin Recipe Section
import AdminRecipes from "./pages/admin/adminrecipes/AdminRecipes";
import CreateRecipe from "./pages/admin/adminrecipes/CreateRecipe";
import ViewRecipe from "./pages/admin/adminrecipes/ViewRecipe";
import EditRecipe from "./pages/admin/adminrecipes/EditRecipe";

// Admin Booking Preview Component
import BookingPreview from "./pages/admin/adminbookings/BookingPreview"; 

// 🔹 Admin Layout for Grouping Protected Routes
const AdminRoutes = () => (
  <ProtectedRoute>
    <Routes>
      <Route path="/admin-dashboard" element={<AdminDashboard />} />
      <Route path="/admin-view-service-plans" element={<ServicePlansAdmin />} />
      <Route path="/admin-create-service-plan" element={<CreateServicePlan />} />
      <Route path="/admin-edit-service-plans/:id" element={<EditServicePlan />} />
      <Route path="/admin-view-bookings" element={<BookingsAdmin />} />
      <Route path="/admin-newsletters" element={<AdminNewsletters />} />
      <Route path="/admin-blog-list" element={<AdminBlogList />} />
      <Route path="/admin-view-blog/:slug" element={<AdminViewBlog />} />
      <Route path="/admin-edit-blog/:slug" element={<AdminEditBlog />} />
      <Route path="/admin-create-blog" element={<AdminCreateBlog />} />

      {/* Admin Recipes Routes */}
      <Route path="/admin-recipes" element={<AdminRecipes />} />
      <Route path="/admin-recipes/create" element={<CreateRecipe />} />
      <Route path="/admin-recipes/view/:slug" element={<ViewRecipe />} />
      <Route path="/admin-recipes/edit/:slug" element={<EditRecipe />} />

      {/* Admin Booking Preview Route */}
      <Route path="/admin-booking-details/:id" element={<BookingPreview />} />
    </Routes>
  </ProtectedRoute>
);

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Header />
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:slug" element={<BlogDetails />} />
          <Route path="/test" element={<TestBlog />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/book-consultation" element={<BookConsultation />} />
          <Route path="/recipes" element={<Recipes />} />
          <Route path="/fullrecipe/:slug" element={<FullRecipe />} />
          
          {/* Policy Routes */}
          <Route path="/terms-of-service" element={<TermsOfService />} /> 
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
          <Route path="/cookies-policy" element={<CookiesPolicy />} /> 

          {/* Admin Routes */}
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/*" element={<AdminRoutes />} />
        </Routes>
        <Footer />
      </Router>
    </LanguageProvider>
  );
}

export default App;
