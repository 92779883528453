import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../config";
import "./BookingPreview.css";

const BookingPreview = () => {
  const { id } = useParams();  // Get the booking ID from the URL
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/bookings/${id}`);  // Fetch the booking by ID
        if (!response.ok) {
          throw new Error("Failed to fetch booking");
        }
        const data = await response.json();
        setBooking(data);  // Set the booking data
      } catch (err) {
        console.error("Error fetching booking:", err);
        setError("Failed to load booking details.");
      } finally {
        setLoading(false);
      }
    };

    fetchBooking();
  }, [id]);  // Add `id` as a dependency to fetch the booking when the ID changes

  if (loading) {
    return <p>Loading booking details...</p>;
  }

  if (error) {
    return <p className="error">{error}</p>;
  }

  return (
    <div className="booking-admin-preview-container">
      <h2>Booking Details</h2>
      
      {booking ? (
        <div>
          <h3>{booking.name}</h3>
          <p><strong>Email:</strong> {booking.email}</p>
          <p><strong>Phone Number:</strong> {booking.phone_number || "No phone number provided"}</p> {/* Added phone number preview */}
          <p><strong>Service Plan:</strong> {booking.service_plan_title || "No service plan selected"}</p>
          <p><strong>Date:</strong> {booking.date}</p>
          <p><strong>Time:</strong> {booking.time}</p>
          <p><strong>Message:</strong> {booking.message || "No message provided"}</p>
          <p><strong>Status:</strong> {booking.status}</p>
        </div>
      ) : (
        <p>No booking found</p>
      )}
    </div>
  );
};

export default BookingPreview;
