import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../config";
import "./BookingsAdmin.css";

const BookingsAdmin = () => {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [deletingId, setDeletingId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all bookings on initial load
  useEffect(() => {
    const controller = new AbortController();
    const fetchBookings = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/bookings`, { signal: controller.signal });
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  
        const data = await response.json();
        setBookings(data);
      } catch (err) {
        if (err.name !== "AbortError") {
          console.error("❌ Error fetching bookings:", err);
          setError("Failed to load bookings.");
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchBookings();
  
    return () => controller.abort(); // Cleanup on unmount
  }, []);
  

  // Delete booking function
  const handleDeleteBooking = async (bookingId) => {
    if (!window.confirm("Are you sure you want to delete this booking?")) return;
  
    setDeletingId(bookingId); // Show loading state for the specific booking
  
    try {
      const response = await fetch(`${API_BASE_URL}/bookings/${bookingId}`, {
        method: "DELETE",
      });
  
      if (!response.ok) throw new Error("Failed to delete booking");
  
      alert("Booking deleted successfully!");
      setBookings((prev) => prev.filter((booking) => booking.id !== bookingId));
    } catch (error) {
      console.error(error);
      alert("Error deleting booking.");
    } finally {
      setDeletingId(null);
    }
  };
  
  // Handle view detailed booking
  const handleViewDetails = (id) => {
    navigate(`/admin-booking-details/${id}`);
  };

  return (
    <div className="admin-bookings">
      <h2 className="admin-title">Manage Bookings</h2>

      {loading ? (
        <p className="loading-text">Loading bookings...</p>
      ) : error ? (
        <p className="error-text">{error}</p>
      ) : bookings.length > 0 ? (
        <div className="admin-bookings-grid">
          {bookings.map((booking) => (
            <div key={booking.id} className="admin-booking-card">
              <h3 className="admin-booking-title">{booking.name}</h3>
              <p className="admin-booking-date">📅 {booking.date}</p>
              <p className="admin-booking-time">🕒 {booking.time}</p>
              <p className="admin-booking-status">Status: {booking.status}</p>

              <p className="admin-service-plan">
                Service Plan: {booking.service_plan_title ? booking.service_plan_title : "No service plan selected"}
              </p>

              <p className="admin-client-email">Email: {booking.email}</p>

              {/* Added Phone Number Preview */}
              <p className="admin-client-phone">Phone Number: {booking.phone_number || "No phone number provided"}</p>

              <p className="admin-client-message">
                Message: {booking.message || "No message provided"}
              </p>

              <div className="admin-buttons">
                <button
                  className="view-button"
                  onClick={() => handleViewDetails(booking.id)}
                >
                  👁️ View Details
                </button>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteBooking(booking.id)}
                  disabled={deletingId === booking.id}
                >
                  {deletingId === booking.id ? "⏳ Deleting..." : "❌ Delete"}
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-data-text">No bookings available.</p>
      )}
    </div>
  );
};

export default BookingsAdmin;
