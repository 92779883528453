import React from "react";
import "./About.css";

// Background component
import PageHero from "../../components/pagehero/PageHero";
import AboutHeroImage from "../../images/aboutimages/BackgroundTitleImage.jpg";

// Sections
import AboutIntro from "./sections/AboutIntro";
import AboutJourney from "./sections/AboutJourney";
import AboutCTA from "./sections/AboutCTA";

const About = () => {
  return (
    <div className="about-container">
      <PageHero
        title="Wellness Begins With What You Eat"
        image={AboutHeroImage}
      />
      <AboutIntro />
      <AboutJourney />
      <AboutCTA />
    </div>
  );
};

export default About;
