import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container p-4 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-700 mb-4">
        Last updated: 2025-03-21
      </p>
      <p className="mb-4">
        This detailed Privacy Policy explains how e-nutritionist ("we", "us", "our") collects, processes, and safeguards
        your personal information.
      </p>

      <h2 className="font-semibold text-lg mt-4">1. Information We Collect</h2>
      <ul className="list-disc ml-8 mb-4">
        <li><strong>Personal Information:</strong> Name, address, email, phone number, age, gender, and health information provided by you during consultations.</li>
        <li><strong>Payment Data:</strong> Payments are securely processed through third-party services; we do not store sensitive payment information.</li>
        <li><strong>Technical Information:</strong> IP address, browser type, and device information collected when you access our website.</li>
      </ul>

      <h2 className="font-semibold text-lg mt-4">2. Purpose of Information Use</h2>
      <ul className="list-disc ml-8 mb-4">
        <li>To deliver personalized nutrition, diet planning, fitness advice, and lifestyle recommendations.</li>
        <li>To manage your account, bookings, payments, and provide customer support.</li>
        <li>To communicate updates, offers, and important information regarding your services.</li>
      </ul>

      <h2 className="font-semibold text-lg mt-4">3. Legal Basis for Data Collection</h2>
      <p className="mb-4">
        We process your data based on your explicit consent given when you sign up for our services, and for the legitimate interests of managing and improving our services.
      </p>

      <h2 className="font-semibold text-lg mt-4">4. Data Sharing and Disclosure</h2>
      <p className="mb-4">
        We do not sell, rent, or share your personal data with third parties unless required by law or to protect our rights. Your health data is strictly confidential and will only be shared with explicit consent.
      </p>

      <h2 className="font-semibold text-lg mt-4">5. Data Security</h2>
      <p className="mb-4">
        We implement strong security measures including encryption and secure servers to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
      </p>

      <h2 className="font-semibold text-lg mt-4">6. Data Retention</h2>
      <p className="mb-4">
        We retain your personal data for as long as necessary to fulfill the purposes described above, comply with legal obligations, or until you request data deletion.
      </p>

      <h2 className="font-semibold text-lg mt-4">7. Your Data Protection Rights</h2>
      <p className="mb-4">
        You have the right to:
      </p>
      <ul className="list-disc ml-8 mb-4">
        <li>Access, rectify, or delete your personal data.</li>
        <li>Restrict or object to data processing.</li>
        <li>Withdraw your consent at any time.</li>
      </ul>
      <p className="mb-4">
        To exercise these rights, contact us directly at our provided contact information.
      </p>

      <h2 className="font-semibold text-lg mt-4">8. International Data Transfers</h2>
      <p className="mb-4">
        Your data may be transferred and processed outside the EU/EEA, subject to adequate security measures and applicable data protection laws.
      </p>

      <h2 className="font-semibold text-lg mt-4">9. Third-Party Services</h2>
      <p className="mb-4">
        Our website may link to third-party websites or services. Their privacy practices are not under our control. Please review their respective policies.
      </p>

      <h2 className="font-semibold text-lg mt-4">10. Changes to Privacy Policy</h2>
      <p className="mb-4">
        We reserve the right to update this policy periodically. Updates will be clearly indicated by the "Last updated" date on our website.
      </p>

      <h2 className="font-semibold text-lg mt-4">11. Contact Us</h2>
      <p className="mb-4">
        For any questions, requests, or complaints regarding privacy:
      </p>
      <ul className="list-none ml-8 mb-4">
        <li><strong>e-nutritionist</strong></li>
        <li>Email: <a href="mailto:thimiosarvanitis@gmail.com" className="text-blue-500">thimiosarvanitis@gmail.com</a></li>
        <li>Phone: +34 613 497 305</li>
        <li>Address: Calle de Cañete 18, Madrid, Spain</li>
      </ul>

      <p className="text-sm text-gray-700">
        By using our website and services, you confirm your understanding and acceptance of this Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
