import React, { useState, useEffect } from "react";
import "./BookConsultation.css";
import BookingCalendar from "./BookingCalendar";
import { useLanguage } from "../../context/LanguageContext";
import PageHero from "../../components/pagehero/PageHero";
import backgroundImg from "../../images/servicesimages/BackgroundTitleImage.jpg";
import { API_BASE_URL } from '../../config';

const BookConsultation = () => {
  const { selectedLanguage, isLanguageLoaded } = useLanguage();
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);
  const [bookedTimes, setBookedTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");  // Renamed state for phone number
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const safeLanguage = isLanguageLoaded ? selectedLanguage : "en";

  // Fetch available services
  useEffect(() => {
    if (!isLanguageLoaded) return;

    fetch(`${API_BASE_URL}/api/v1/service_plans?language=${safeLanguage}`)
      .then((res) => res.json())
      .then((data) => {
        setServices(Array.isArray(data) ? data : []);
      })
      .catch((err) => {
        console.error("❌ Error fetching services:", err);
        setServices([]);
        setError("❌ Unable to fetch services. Please try again later.");
      });
  }, [safeLanguage, isLanguageLoaded]);

  // Fetch available times for the selected date
  useEffect(() => {
    if (!selectedDate) return;

    fetch(`${API_BASE_URL}/bookings/available_times?date=${selectedDate}`)
      .then((res) => res.json())
      .then((data) => {
        setAvailableTimes(data.available_times || []);
        setBookedTimes(data.booked_times || []);
      })
      .catch((err) => {
        console.error("❌ Error fetching available times:", err);
        setAvailableTimes([]);
        setBookedTimes([]);
        setError("❌ Unable to fetch available times. Please try again later.");
      });
  }, [selectedDate]);

  // Validate phone number format (basic example for a 10-digit number)
  const validatePhone = (phone_number) => {
    const phoneRegex = /^[0-9]{10}$/;  // Adjust the regex based on your country's phone format
    return phoneRegex.test(phone_number);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!selectedService || !selectedDate || !selectedTime || !name || !email || !phone_number || !validatePhone(phone_number)) {
      setError("⚠ Please fill in all required fields and ensure phone number is valid.");
      return;
    }

    setIsSubmitting(true); // Disable submit button during submission

    const bookingData = {
      service_plan_id: selectedService,
      date: selectedDate,
      time: selectedTime,
      name,
      email,
      phone_number: phone_number,  // Passing phone_number as phone
      message,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/bookings`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bookingData),
      });

      const data = await response.json();
      if (response.ok) {
        alert("✅ Booking confirmed! A confirmation email has been sent.");
        setSelectedService("");
        setSelectedDate("");
        setSelectedTime("");
        setName("");
        setEmail("");
        setPhoneNumber("");  // Reset phone number
        setMessage("");
      } else {
        setError(data.error || "⚠ Booking failed. Try again.");
      }
    } catch (error) {
      setError("❌ An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false); // Re-enable submit button after submission
    }
  };

  // Language translations
  const translations = {
    en: {
      title: "Book a Consultation",
      selectService: "Select a Service",
      selectDate: "Select Date",
      selectTime: "Select Time",
      name: "Name",
      email: "Email",
      phone: "Phone Number",  // Translation for phone
      message: "Message (Optional)",
      bookNow: "Book Now",
      placeholderService: "-- Select a Service --",
      placeholderTime: "-- Select a Time --",
    },
    es: {
      title: "Reservar una consulta",
      selectService: "Seleccionar un servicio",
      selectDate: "Seleccionar fecha",
      selectTime: "Seleccionar hora",
      name: "Nombre",
      email: "Correo electrónico",
      phone: "Número de teléfono",  // Translation for phone
      message: "Mensaje (opcional)",
      bookNow: "Reservar ahora",
      placeholderService: "-- Seleccione un servicio --",
      placeholderTime: "-- Seleccione una hora --",
    },
    gr: {
      title: "Κλείστε ραντεβού",
      selectService: "Επιλέξτε μια υπηρεσία",
      selectDate: "Επιλέξτε ημερομηνία",
      selectTime: "Επιλέξτε ώρα",
      name: "Όνομα",
      email: "Email",
      phone: "Αριθμός τηλεφώνου",  // Translation for phone
      message: "Μήνυμα (προαιρετικό)",
      bookNow: "Κλείστε τώρα",
      placeholderService: "-- Επιλέξτε μια υπηρεσία --",
      placeholderTime: "-- Επιλέξτε ώρα --",
    },
  };

  const t = translations[selectedLanguage] || translations["en"];

  return (
    <>
      <PageHero
        image={backgroundImg}
        title={t.title}
      />
      <div className="booking-container">
        <h2>{t.title}</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit} className="booking-form">
          {/* Select Service */}
          <label>{t.selectService}:</label>
          <select value={selectedService} onChange={(e) => setSelectedService(e.target.value)} required>
            <option value="">{t.placeholderService}</option>
            {services.length > 0 ? (
              services.map((service) => (
                <option key={service.id} value={service.id}>
                  {service.title}
                </option>
              ))
            ) : (
              <option disabled>No services available</option>
            )}
          </select>

          {/* Select Date */}
          <label>{t.selectDate}:</label>
          <BookingCalendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

          {/* Select Time */}
          <label>{t.selectTime}:</label>
          <select value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} required>
            <option value="">{t.placeholderTime}</option>
            {availableTimes.length > 0 ? (
              availableTimes.map((time) => (
                <option key={time} value={time} disabled={bookedTimes.includes(time)}>
                  {time} {bookedTimes.includes(time) ? " (Booked)" : ""}
                </option>
              ))
            ) : (
              <option disabled>No available times</option>
            )}
          </select>

          {/* Name */}
          <label>{t.name}:</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />

          {/* Email */}
          <label>{t.email}:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

          {/* Phone */}
          <label>{t.phone}:</label>
          <input type="text" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} required />

          {/* Message (Optional) */}
          <label>{t.message}:</label>
          <textarea value={message} onChange={(e) => setMessage(e.target.value)} />

          {/* Submit Button */}
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : t.bookNow}
          </button>
        </form>
      </div>
    </>
  );
};

export default BookConsultation;
