import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../config";
import "./EditRecipe.css";

const EditRecipe = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    description: "",
    category: "",
    slug: "",
    language: "en",
    difficulty: "",
    duration: "",
    persons: "",
    ingredients: [],
    instructions: [],
  });

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/recipes/${slug}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` },
        });

        if (response.ok) {
          const data = await response.json();
          setRecipe(data);
          setFormData({
            title: data.title,
            image: data.image,
            description: data.description,
            category: data.category,
            slug: data.slug,
            language: data.language,
            difficulty: data.valuable_info?.difficulty || "",
            duration: data.valuable_info?.duration || "",
            persons: data.valuable_info?.persons || "",
            ingredients: data.ingredients || [],
            instructions: data.instructions || [],
          });
        } else {
          setError("Failed to load recipe.");
        }
      } catch (err) {
        setError("Network error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchRecipe();
  }, [slug]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleIngredientChange = (index, e) => {
    const { name, value } = e.target;
    const updatedIngredients = [...formData.ingredients];
    updatedIngredients[index] = { ...updatedIngredients[index], [name]: value };
    setFormData({ ...formData, ingredients: updatedIngredients });
  };

  const handleInstructionChange = (index, e) => {
    const { name, value } = e.target;
    const updatedInstructions = [...formData.instructions];
    updatedInstructions[index] = { ...updatedInstructions[index], [name]: value };
    setFormData({ ...formData, instructions: updatedInstructions });
  };

  const addIngredient = () => {
    setFormData((prevData) => ({
      ...prevData,
      ingredients: [...prevData.ingredients, { name: "", quantity_unit: "", description: "" }],
    }));
  };

  const addInstruction = () => {
    setFormData((prevData) => ({
      ...prevData,
      instructions: [...prevData.instructions, { step_number: "", step: "", note: "" }],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recipeParams = {
      title: formData.title,
      image: formData.image,
      description: formData.description,
      category: formData.category,
      slug: formData.slug,
      language: formData.language,
      ingredients: formData.ingredients,
      instructions: formData.instructions,
      valuable_info: {
        difficulty: formData.difficulty,
        duration: formData.duration,
        persons: formData.persons,
      },
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/recipes/${slug}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
        body: JSON.stringify({ recipe: recipeParams }),
      });

      if (response.ok) {
        alert("Recipe updated successfully.");
        navigate("/admin-recipes");
      } else {
        setError("Failed to update recipe.");
      }
    } catch (err) {
      setError("Network error occurred.");
    }
  };

  const handleCancel = () => {
    navigate("/admin-recipes");
  };

  if (loading) {
    return <p>Loading recipe...</p>;
  }

  return (
    <div className="edit-recipe-container">
      <h1>Edit Recipe</h1>
      {error && <p className="error">{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="image">Image URL</label>
          <input
            type="text"
            id="image"
            name="image"
            value={formData.image}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="category">Category</label>
          <input
            type="text"
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="slug">Slug</label>
          <input
            type="text"
            id="slug"
            name="slug"
            value={formData.slug}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="language">Language</label>
          <input
            type="text"
            id="language"
            name="language"
            value={formData.language}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="difficulty">Difficulty</label>
          <input
            type="text"
            id="difficulty"
            name="difficulty"
            value={formData.difficulty}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="duration">Duration</label>
          <input
            type="text"
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="persons">Persons</label>
          <input
            type="text"
            id="persons"
            name="persons"
            value={formData.persons}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <h3>Ingredients</h3>
          {formData.ingredients.map((ingredient, index) => (
            <div key={index} className="ingredient">
              <input
                type="text"
                name="name"
                placeholder="Ingredient Name"
                value={ingredient.name}
                onChange={(e) => handleIngredientChange(index, e)}
              />
              <input
                type="text"
                name="quantity_unit"
                placeholder="Quantity"
                value={ingredient.quantity_unit}
                onChange={(e) => handleIngredientChange(index, e)}
              />
              <input
                type="text"
                name="description"
                placeholder="Description"
                value={ingredient.description}
                onChange={(e) => handleIngredientChange(index, e)}
              />
            </div>
          ))}
          <button type="button" onClick={addIngredient}>
            Add Ingredient
          </button>
        </div>

        <div className="form-group">
          <h3>Instructions</h3>
          {formData.instructions.map((instruction, index) => (
            <div key={index} className="instruction">
              <input
                type="number"
                name="step_number"
                placeholder="Step Number"
                value={instruction.step_number}
                onChange={(e) => handleInstructionChange(index, e)}
              />
              <textarea
                name="step"
                placeholder="Step"
                value={instruction.step}
                onChange={(e) => handleInstructionChange(index, e)}
              />
              <textarea
                name="note"
                placeholder="Note"
                value={instruction.note}
                onChange={(e) => handleInstructionChange(index, e)}
              />
            </div>
          ))}
          <button type="button" onClick={addInstruction}>
            Add Instruction
          </button>
        </div>

        <div className="form-buttons">
          <button type="submit">Update Recipe</button>
          <button type="button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditRecipe;
