import React from 'react';

import time from "../../images/recipesimages/time.svg"
import bown from "../../images/recipesimages/bown.svg"
import cook from "../../images/recipesimages/cook.svg"

import './RenderValuableInformation.css';  // Import CSS for styling

const RenderValuableInformation = ({ valuableInfo }) => {
  return (
    <div className="valuable-info-placeholder">
      <div className="info-pair">
        <img src={time} alt="Duration" />
        <p className="duration">
          {valuableInfo?.duration ? valuableInfo.duration : "Not specified"}
        </p>
      </div>
      <div className="info-pair">
        <img src={cook} alt="Difficulty" />
        <p className="difficulty">
          {valuableInfo?.difficulty ? valuableInfo.difficulty : "Not specified"}
        </p>
      </div>
      <div className="info-pair">
        <img src={bown} alt="Servings" />
        <p className="quantity">
          {valuableInfo?.persons ? valuableInfo.persons : "Not specified"}
        </p>
      </div>
    </div>
  );
};

export default RenderValuableInformation;
