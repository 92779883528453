import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../../context/LanguageContext"; // ✅ Language context
import "./EditServicePlan.css"; // ✅ Ensure CSS exists
import { API_BASE_URL } from '../../../config';

const EditServicePlan = () => {
  const { id } = useParams(); // ✅ Get service plan ID from URL
  const navigate = useNavigate();
  const { language } = useLanguage(); // ✅ Get selected language

  const [servicePlan, setServicePlan] = useState({
    title: "",
    price: "",
    description: "",
    order: "", // ✅ Added order field
    features: [],
    language: language || "en",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchServicePlan = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/service_plans/${id}`);
        if (!response.ok) throw new Error("Service plan not found");

        const data = await response.json();
        setServicePlan(data);
        setLoading(false);
      } catch (err) {
        console.error("❌ Error fetching service plan:", err);
        setError("Service plan not found.");
        setLoading(false);
      }
    };

    fetchServicePlan();
  }, [id]);

  // ✅ Handle input change
  const handleChange = (e) => {
    setServicePlan({ ...servicePlan, [e.target.name]: e.target.value });
  };

  // ✅ Handle feature change
  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...servicePlan.features];
    updatedFeatures[index].name = value;
    setServicePlan({ ...servicePlan, features: updatedFeatures });
  };

  // ✅ Add new feature
  const addFeature = () => {
    setServicePlan({ ...servicePlan, features: [...servicePlan.features, { name: "" }] });
  };

  // ✅ Remove feature and mark for deletion
    const removeFeature = async (index) => {
      const featureToDelete = servicePlan.features[index];

      // Send DELETE request to the backend to remove the feature
      try {
        const token = localStorage.getItem("adminToken"); // Get the token from local storage

        const response = await fetch(`${API_BASE_URL}/api/v1/service_plans/${id}/features/${featureToDelete.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`, // Send the token for authorization
          },
        });

        if (!response.ok) throw new Error("Failed to remove feature");

        // If successful, update state to remove feature from UI
        const updatedFeatures = servicePlan.features.filter((_, i) => i !== index);
        setServicePlan({ ...servicePlan, features: updatedFeatures });

        console.log("Feature removed successfully");
      } catch (err) {
        console.error("❌ Error removing feature:", err);
        setError("Failed to remove feature.");
      }
    };

  // ✅ Handle form submission (Fixed Features & Order Saving)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError("");

    // Ensure features are formatted properly for the backend
    const updatedServicePlan = {
      ...servicePlan,
      order: parseInt(servicePlan.order, 10), // Convert order to integer
      features_attributes: servicePlan.features.map((feature) => ({
        id: feature.id || null,
        name: feature.name,
        _destroy: feature._destroy || false, // Send _destroy flag for deleted features
      })),
    };

    // Retrieve the JWT token (assuming it's stored in localStorage)
    const token = localStorage.getItem('adminToken'); // Adjust based on where you store the token

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/service_plans/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Pass the token here
        },
        body: JSON.stringify(updatedServicePlan), // Send formatted data
      });

      if (!response.ok) throw new Error("Failed to update service plan");

      navigate("/admin-view-service-plans"); // Redirect after successful update
    } catch (err) {
      console.error("❌ Error updating service plan:", err);
      setError("Failed to update service plan.");
    } finally {
      setSaving(false);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div className="edit-service-plan-container">
      <h2>Edit Service Plan</h2>

      <form onSubmit={handleSubmit} className="edit-service-plan-form">
        <label>Title:</label>
        <input type="text" name="title" value={servicePlan.title} onChange={handleChange} required />

        <label>Price (€):</label>
        <input type="number" name="price" value={servicePlan.price} onChange={handleChange} required />

        <label>Description:</label>
        <textarea name="description" value={servicePlan.description} onChange={handleChange} required />

        {/* ✅ Order Input Field */}
        <label>Order:</label>
        <input type="number" name="order" value={servicePlan.order} onChange={handleChange} required />

        <label>Features:</label>
        {servicePlan.features.map((feature, index) => (
          <div key={index} className="feature-item">
            <input
              type="text"
              value={feature.name}
              onChange={(e) => handleFeatureChange(index, e.target.value)}
              required
            />
            <button type="button" onClick={() => removeFeature(index)}>❌ Remove</button>
          </div>
        ))}
        <button type="button" onClick={addFeature} className="add-feature-button">➕ Add Feature</button>

        <div className="admin-buttons">
          <button type="submit" className="save-button" disabled={saving}>
            {saving ? "💾 Saving..." : "💾 Save"}
          </button>
          <button type="button" className="cancel-button" onClick={() => navigate("/admin-view-service-plans")}>
            ❌ Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditServicePlan;
