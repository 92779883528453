import React, { createContext, useContext, useState, useEffect } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // ✅ Default to English
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false); // ✅ Track if language is loaded

  useEffect(() => {
    setIsLanguageLoaded(true); // ✅ Ensure the language is set before loading components
  }, []);

  const changeLanguage = (lang) => {
    console.log("🔄 Changing language to:", lang);
    setSelectedLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ selectedLanguage, changeLanguage, isLanguageLoaded }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
