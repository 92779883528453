import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLanguage } from "../../context/LanguageContext"; // Global language context
import { useNavigate } from "react-router-dom";
import "./ServicePlans.css";
import { API_BASE_URL } from '../../config';

const ServicePlans = () => {
  const { selectedLanguage, isLanguageLoaded } = useLanguage(); // ✅ Fix: Use correct context value
  const [servicePlans, setServicePlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const serviceRefs = useRef([]); // Store refs for service cards
  const navigate = useNavigate(); // Initialize navigation function

  const safeLanguage = selectedLanguage || "en"; // ✅ Fallback to "en"

  // Memoize the fetch function
  const fetchServicePlans = useCallback(async () => {
    if (!isLanguageLoaded) return; // ✅ Ensure language is loaded before fetching
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/service_plans?language=${safeLanguage}`); // ✅ Ensure correct endpoint
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  
      const data = await response.json();
      console.log("✅ API Response:", data);
  
      setServicePlans(data ?? []); // Simplified array check
    } catch (err) {
      console.error("❌ Error fetching service plans:", err);
      setError(err.message || "Failed to load service plans.");
    } finally {
      setLoading(false);
    }
  }, [safeLanguage, isLanguageLoaded]);

  useEffect(() => {
    fetchServicePlans();
  }, [fetchServicePlans]); // ✅ Fix: Add dependencies to prevent infinite loop

  // Lazy Load Animation using IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        }
      });
    }, { threshold: 0.2 });

    const elements = serviceRefs.current.filter(Boolean); // Ensure only valid refs
    elements.forEach((el) => observer.observe(el));

    return () => elements.forEach((el) => observer.unobserve(el));
  }, [servicePlans]); // Runs only when servicePlans change

  const handleBookNow = (planId) => {
    navigate(`/book-consultation?service=${planId}`);
  };

  return (
    <div className="service-plans-container">
      {loading ? (
        <p className="loading-text">Loading service plans...</p>
      ) : error ? (
        <p className="error-text">{error}</p>
      ) : servicePlans.length > 0 ? (
        <div className="service-plans-grid">
          {servicePlans.map((plan, index) => (
            <div
              key={plan.id}
              ref={(el) => (serviceRefs.current[index] = el)}
              className="service-card lazy-load"
            >
              <h3 className="service-title">{plan.title}</h3>
              <p className="service-price">{plan.price}<span>€</span></p>
              <span className="seperator-line"></span>
              {/* Render Features if they exist */}
              {plan.features && plan.features.length > 0 ? (
                <ul className="features-list">
                  {plan.features.map((feature) => (
                    <li key={feature.id} className="feature-item">
                      <span className="feature-tick">✓</span>
                      <strong className="feature-name">{feature.name}</strong>
                    </li>
                  ))}
                </ul>
              ) : null} {/* If no features, don't render anything */}
              <p className="service-description">{plan.description}</p>
              {/* Book Now Button */}
              <button className="cta-button" onClick={() => handleBookNow(plan.id)}>
                Book Now
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-data-text">No service plans available.</p>
      )}
    </div>
  );
};

export default ServicePlans;
