import React, { useState } from "react";

const HtmlCustomEditor = ({ content, onSave, onClose }) => {
  const [htmlContent, setHtmlContent] = useState(content || "");

  // Function to handle content change in the editor
  const handleChange = (e) => {
    setHtmlContent(e.target.value);
  };

  // Function to save the content (as raw HTML string)
  const handleSave = () => {
    onSave(htmlContent);  // Pass the raw HTML string to the parent
    onClose();
  };

  return (
    <div className="html-custom-editor">
      <h3>HTML Custom Editor</h3>
      <textarea
        value={htmlContent}
        onChange={handleChange}
        rows="10"
        cols="80"
        placeholder="Write your HTML content here..."
      />
      <div className="editor-actions">
        <button onClick={handleSave}>Save</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default HtmlCustomEditor;
