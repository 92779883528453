// ✅ Import images only once & reuse them
import nutritionalEducation from "../../../images/homeimages/service-info-section/Nutricional-education.webp";
import recomposition from "../../../images/homeimages/service-info-section/Recomposition.webp";
import exerciseNutrition from "../../../images/homeimages/service-info-section/Exercise-Nutrition.webp";
import digestive from "../../../images/homeimages/service-info-section/digestive.webp";
import allergies from "../../../images/homeimages/service-info-section/allergies.webp";
import pathologies from "../../../images/homeimages/service-info-section/pathologies.webp";
import vegan from "../../../images/homeimages/service-info-section/vegan.webp";
// ✅ Define base concepts with translations
const baseConcepts = [
  {
    id: 1,
    imgUrl: nutritionalEducation,
    translations: {
      en: { 
        title: "Nutritional Education", 
        description: "This service focuses on educating you about healthy eating habits and nutritional knowledge. It aims to empower individuals to make informed food choices for a healthier lifestyle." 
      },
      es: { 
        title: "Educación Nutricional", 
        description: "Este servicio se centra en educarte sobre hábitos alimenticios saludables y conocimientos nutricionales. Su objetivo es empoderarte para que tomes decisiones informadas sobre la alimentación y lleves un estilo de vida más saludable." 
      },
      gr: { 
        title: "Διατροφική Εκπαίδευση", 
        description: "Αυτό το πρόγραμμα επικεντρώνεται στην εκπαίδευσή σας σχετικά με υγιεινές διατροφικές συνήθειες και γνώσεις διατροφής. Στόχος του είναι να σας δώσει τη δύναμη να κάνετε συνειδητές επιλογές τροφίμων για έναν πιο υγιεινό τρόπο ζωής." 
      }
    }
  },
  {
    id: 2,
    imgUrl: recomposition,
    translations: {
      en: { 
        title: "Body Recomposition", 
        description: "Tailored to help clients change their body composition, typically focusing on reducing fat mass and increasing muscle mass. It involves personalized nutritional strategies aligned with specific fitness goals." 
      },
      es: { 
        title: "Recomposición Corporal", 
        description: "Diseñado para ayudar a los clientes a cambiar su composición corporal, enfocándose en la reducción de grasa y el aumento de masa muscular. Incluye estrategias nutricionales personalizadas alineadas con objetivos específicos de fitness." 
      },
      gr: { 
        title: "Ανασύνθεση Σώματος", 
        description: "Σχεδιασμένο για να βοηθήσει τους πελάτες να αλλάξουν τη σύσταση του σώματός τους, εστιάζοντας κυρίως στη μείωση του λίπους και την αύξηση της μυϊκής μάζας. Περιλαμβάνει εξατομικευμένες διατροφικές στρατηγικές προσαρμοσμένες στους προσωπικούς στόχους fitness." 
      }
    }
  },
  {
    id: 3,
    imgUrl: exerciseNutrition,
    translations: {
      en: { 
        title: "Exercise Nutrition", 
        description: "Nutritional guidance specifically designed to support exercise and athletic performance. It emphasizes the importance of proper nutrition for energy, recovery, and overall performance enhancement." 
      },
      es: { 
        title: "Nutrición para el Ejercicio", 
        description: "Guía nutricional diseñada específicamente para mejorar el rendimiento deportivo y apoyar la actividad física. Destaca la importancia de una nutrición adecuada para la energía, la recuperación y la mejora del rendimiento general." 
      },
      gr: { 
        title: "Διατροφή για Άσκηση", 
        description: "Διατροφικές κατευθύνσεις σχεδιασμένες ειδικά για να υποστηρίξουν την άσκηση και την αθλητική απόδοση. Δίνει έμφαση στη σωστή διατροφή για ενέργεια, αποκατάσταση και συνολική βελτίωση της απόδοσης." 
      }
    }
  },
  {
    id: 4,
    imgUrl: digestive,
    translations: {
      en: { 
        title: "Digestive Symptoms", 
        description: "Addresses various digestive issues such as bloating, indigestion, and irregular bowel movements. It involves identifying and managing dietary factors that might be contributing to these symptoms." 
      },
      es: { 
        title: "Síntomas Digestivos", 
        description: "Aborda diversas molestias digestivas como hinchazón, indigestión y alteraciones en el tránsito intestinal. Consiste en identificar y manejar factores dietéticos que puedan estar contribuyendo a estos síntomas." 
      },
      gr: { 
        title: "Πεπτικά Συμπτώματα", 
        description: "Αντιμετωπίζει διάφορα πεπτικά προβλήματα όπως φούσκωμα, δυσπεψία και ακανόνιστες εντερικές κινήσεις. Περιλαμβάνει την αναγνώριση και διαχείριση διατροφικών παραγόντων που μπορεί να συμβάλλουν σε αυτά τα συμπτώματα." 
      }
    }
  },
  {
    id: 5,
    imgUrl: allergies,
    translations: {
      en: { 
        title: "Allergies and Intolerances", 
        description: "Focused on identifying and managing food allergies and intolerances. It includes developing personalized diets to avoid allergens and intolerant foods while ensuring nutritional adequacy." 
      },
      es: { 
        title: "Alergias e Intolerancias", 
        description: "Enfocado en la identificación y manejo de alergias e intolerancias alimentarias. Incluye la creación de dietas personalizadas para evitar alérgenos y alimentos problemáticos, garantizando al mismo tiempo un equilibrio nutricional." 
      },
      gr: { 
        title: "Αλλεργίες και Δυσανεξίες", 
        description: "Εστιάζει στον εντοπισμό και τη διαχείριση τροφικών αλλεργιών και δυσανεξιών. Περιλαμβάνει τη δημιουργία εξατομικευμένων διαιτών που αποφεύγουν τα αλλεργιογόνα, διασφαλίζοντας παράλληλα τη διατροφική επάρκεια." 
      }
    }
  },
  {
    id: 6,
    imgUrl: pathologies,
    translations: {
      en: { 
        title: "Pathologies", 
        description: "Nutritional management and support for various health conditions and diseases. It involves creating dietary plans that cater to the specific needs and restrictions of different pathologies." 
      },
      es: { 
        title: "Patologías", 
        description: "Gestión y apoyo nutricional para diversas enfermedades y condiciones de salud. Implica la elaboración de planes dietéticos adaptados a las necesidades y restricciones específicas de cada patología." 
      },
      gr: { 
        title: "Παθολογίες", 
        description: "Διατροφική διαχείριση και υποστήριξη για διάφορες παθήσεις και προβλήματα υγείας. Περιλαμβάνει τη δημιουργία εξατομικευμένων διατροφικών πλάνων που ανταποκρίνονται στις ανάγκες και τους περιορισμούς κάθε παθολογίας." 
      }
    }
},
{
    id: 7,
    imgUrl: vegan,
    translations: {
      en: { 
        title: "Vegan Nutrition", 
        description: "This service is dedicated to providing nutritional advice for those following a vegan diet. It ensures that all nutritional needs are met through plant-based sources, focusing on a balanced and healthy vegan diet." 
      },
      es: { 
        title: "Nutrición Vegana", 
        description: "Este servicio está dedicado a proporcionar asesoramiento nutricional a quienes siguen una dieta vegana. Garantiza que se cubran todas las necesidades nutricionales a través de fuentes vegetales, promoviendo una alimentación equilibrada y saludable." 
      },
      gr: { 
        title: "Βίγκαν Διατροφή", 
        description: "Αυτό το πρόγραμμα παρέχει διατροφικές συμβουλές για όσους ακολουθούν μια βίγκαν διατροφή. Διασφαλίζει ότι καλύπτονται όλες οι διατροφικές ανάγκες μέσω φυτικών πηγών, προωθώντας μια ισορροπημένη και υγιεινή διατροφή." 
      }
    }
}
];

// ✅ Generate different language arrays dynamically
export const ArrayOfConceptsEn = baseConcepts.map(({ id, imgUrl, translations }) => ({
  id,
  imgUrl,
  title: translations.en.title,
  description: translations.en.description
}));

export const ArrayOfConceptsEs = baseConcepts.map(({ id, imgUrl, translations }) => ({
  id,
  imgUrl,
  title: translations.es.title,
  description: translations.es.description
}));

export const ArrayOfConceptsGr = baseConcepts.map(({ id, imgUrl, translations }) => ({
  id,
  imgUrl,
  title: translations.gr.title,
  description: translations.gr.description
}));