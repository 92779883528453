import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./AdminBlogForm.css";
import { API_BASE_URL } from '../../../config';

const AdminEditBlog = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState({
    title: "",
    image: "",
    description: "",
    category: "",
    htmlcontent: "",
    published_at: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    language: "en",
  });

  // Fetch the blog details when the component mounts
  useEffect(() => {
    fetch(`${API_BASE_URL}/api/v1/blogs/${slug}`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming data is the blog object
        if (data.error) {
          alert("Blog not found.");
          navigate("/admin-blog-list");
        } else {
          setBlog(data);
        }
      })
      .catch((error) => console.error("Error fetching blog:", error));
  }, [slug, navigate]);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem("adminToken");
    if (!token) {
      alert("Unauthorized: Please log in as admin.");
      return;
    }

    fetch(`${API_BASE_URL}/api/v1/admin/blogs/${slug}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(blog),  // Directly passing blog object
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(`Error updating blog: ${data.error}`);
        } else {
          alert("Blog updated successfully");
          navigate("/admin-blog-list");
        }
      })
      .catch((error) => console.error("Error updating blog:", error));
  };

  // Handle field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlog((prevBlog) => ({
      ...prevBlog,
      [name]: value,
    }));
  };

  return (
    <div className="admin-blog-form">
      <h1>Edit Blog</h1>
      <form onSubmit={handleSubmit}>
        <label>Title:</label>
        <input
          type="text"
          name="title"
          value={blog.title}
          onChange={handleChange}
          placeholder="Title"
          required
        />

        <label>Image URL:</label>
        <input
          type="text"
          name="image"
          value={blog.image}
          onChange={handleChange}
          placeholder="Image URL"
        />

        <label>Description:</label>
        <textarea
          name="description"
          value={blog.description}
          onChange={handleChange}
          placeholder="Description"
          required
        />

        <label>Category:</label>
        <input
          type="text"
          name="category"
          value={blog.category}
          onChange={handleChange}
          placeholder="Category"
          required
        />

        <label>Published Date:</label>
        <input
          type="date"
          name="published_at"
          value={blog.published_at}
          onChange={handleChange}
        />

        <label>Meta Title:</label>
        <input
          type="text"
          name="meta_title"
          value={blog.meta_title}
          onChange={handleChange}
          placeholder="Meta Title"
        />

        <label>Meta Description:</label>
        <textarea
          name="meta_description"
          value={blog.meta_description}
          onChange={handleChange}
          placeholder="Meta Description"
        />

        <label>Meta Keywords:</label>
        <input
          type="text"
          name="meta_keywords"
          value={blog.meta_keywords}
          onChange={handleChange}
          placeholder="Meta Keywords"
        />

        <label>Language:</label>
        <select
          name="language"
          value={blog.language}
          onChange={handleChange}
        >
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="gr">Greek</option>
        </select>

        <label>Content:</label>
        <textarea
          name="htmlcontent"
          value={blog.htmlcontent}
          onChange={handleChange}
          placeholder="Blog Content"
          required
        />

        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default AdminEditBlog;
