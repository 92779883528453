import React, { useState } from "react";
import { API_BASE_URL } from '../../config';
import "./ContactForm.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/contact_messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ contact_message: formData }),
      });

      if (response.ok) {
        setMessage("Your message has been sent successfully!");
        setFormData({ name: "", email: "", message: "" });
        setError("");
      } else {
        const errorData = await response.json();
        setError(errorData.error || "Something went wrong. Please try again.");
        setMessage("");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      setMessage("");
    }
  };

  return (
    <div className="contact-form-section">
      <form className="contact-form" onSubmit={handleSubmit}>
        <h2>Send a Message</h2>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="Your Message"
          rows="5"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button type="submit" className="cta-button">Send Message</button>
        {message && <p className="form-message">{message}</p>}
        {error && <p className="form-error">{error}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
