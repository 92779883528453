import { Link } from "react-router-dom";
import "./LogoSection.css";
import logo from "../../images/headerimages/logo.svg";

const LogoSection = () => {
  return (
    <div className="homepg-logo">
      <Link to="/" className="logo-link">
        <div className="logo-pack">
          <img src={logo} alt="Logo" className="logo-img" />
          <div className="logo-text">
            <p className="author-name">Thymios Arvanitis</p>
            <p className="author-job">Nutritionist</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default LogoSection;
