import React from 'react';
import './RenderRecipe.css';  // Ensure the updated CSS is applied

import beetrootimg from "../../images/recipesimages/beetroot_img.jpg"
import RenderValuableInformation from './RenderValuableInformation';

const RenderRecipe = ({ recipe }) => {
  const { title, description } = recipe;

  return (
    <div className="recipe-container">
      {/* Recipe Image */}
      <img src={beetrootimg} alt={title} className="recipe-image" />

      {/* Recipe Title */}
      <h2 className="recipe-title">{title}</h2>

      {/* Recipe Description */}
      <p className="recipe-description">{description}</p>

      {/* Render Valuable Information */}
      <RenderValuableInformation valuableInfo={recipe.valuable_info} /> {/* Correct key used */}
    </div>
  );
};

export default RenderRecipe;
