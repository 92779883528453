import React, { useEffect, useRef } from "react";
import "./AboutIntro.css";
import ProfileImage from "../../../images/aboutimages/imageOfMe.png";

const AboutIntro = () => {
  const introRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      { threshold: 0.2 } // Trigger when 20% of element is visible
    );

    if (introRef.current) {
      const elements = introRef.current.querySelectorAll(".lazy-load");
      elements.forEach((el) => observer.observe(el));
    }

    return () => {
      if (introRef.current) {
        const elements = introRef.current.querySelectorAll(".lazy-load");
        elements.forEach((el) => observer.unobserve(el));
      }
    };
  }, []);

  return (
    <section className="about-intro" ref={introRef}>
      <div className="about-image lazy-load">
        <img src={ProfileImage} alt="Portrait of Thymios Arvanitis" />
      </div>
      <div className="about-text lazy-load">
        <h2>Thymios Arvanitis</h2>
        <p>
          Hello, I’m Thymios Arvanitis, a dedicated dietitian specializing in
          personalized nutrition, sports performance, and transformative
          lifestyle changes. My mission is to guide you on a journey toward
          better health, equipping you with the tools to achieve your goals
          through tailored plans and expert support.
        </p>
        <h3>Specialties</h3>
        <ul className="about-specialties">
          <li>Sports and Performance Nutrition</li>
          <li>Weight Management</li>
          <li>Recipe Development and Meal Planning</li>
          <li>Micronutrient Optimization</li>
          <li>Integrative and Holistic Nutrition</li>
          <li>Nutritional Education</li>
          <li>Heart & Cardiovascular Health</li>
          <li>Diabetes Treatment</li>
        </ul>
      </div>
    </section>
  );
};

export default AboutIntro;
